import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';

import { query, collection, where, getDoc, getDocs, doc } from "firebase/firestore";
import { SAFE, SECURE, ULTRA_SECURE, ADMINS_TABLE, CARS_TABLE, DRIVERS_TABLE, RYDERS_TABLE, CODES_TABLE, BOOKED_RIDES_TABLE,
    MAX_PASSENGERS_CAR_SEATS, MAX_PASSENGERS_SUV_SEATS, MAX_PASSENGERS_VAN_SEATS, MAX_PASSENGERS_BUS_SEATS,
    MAX_PASSENGERS_CAR_INCLUDED, MAX_PASSENGERS_SUV_INCLUDED, MAX_PASSENGERS_VAN_INCLUDED, MAX_PASSENGERS_BUS_INCLUDED,
    COST_RENTAL_CAR_DAILY_FB, COST_RENTAL_SUV_DAILY_FB, COST_RENTAL_VAN_DAILY_FB, COST_RENTAL_BUS_DAILY_FB,
    COST_RENTAL_CAR_HOURLY_FB, COST_RENTAL_SUV_HOURLY_FB, COST_RENTAL_VAN_HOURLY_FB, COST_RENTAL_BUS_HOURLY_FB,
    LAUNCH_DATE_REGION_JAM, LAUNCH_DATE_REGION_SAC, LAUNCH_DATE_REGION_ABQ } from './constants';

export let passengers_additional_fares = 0;

export async function  getUserInfo(db, userId) {
    const ryderDoc = await getDocs(query(collection(db, RYDERS_TABLE), where('userId', '==', userId)));
    if (ryderDoc.docs.length === 1) {
        const ryder = ryderDoc.docs[0].data();
        return {id: ryderDoc.docs[0].id, firstName: ryder.firstName, lastName: ryder.lastName, phoneNumber: ryder.phoneNumber, email: ryder.email };
    }
    return null;
}

export async function  getAdminInfo(db, userId) {
    const adminDoc = await getDocs(query(collection(db, ADMINS_TABLE), where('uid', '==', userId)));
    if (adminDoc.docs.length === 1) {
        const admin = adminDoc.docs[0].data();
        return {id: adminDoc.docs[0].id, firstName: admin.firstName, lastName: admin.lastName, email: admin.email };
    }
    return null;
}

export async function getDriverId(db) {
    const driversDoc = await getDocs(collection(db, DRIVERS_TABLE));
    if (driversDoc.docs.length > 0) {
        const driver = driversDoc.docs[0];
        return driver.id;
    }
    return null;
}

export async function getDriverInfo(db, driverId) {
    const driverDoc = await getDoc(doc(db, DRIVERS_TABLE, driverId));
    if (driverDoc) {
        const driver = driverDoc.data();
        return {...driver, id: driverId };
    }
    return null;
}

export async function getCarInfo(db, carId) {
    const carDoc = await getDoc(doc(db, CARS_TABLE, carId));
    if (carDoc) {
        const car = carDoc.data();
        return {...car, id: carId };
    }
    return null;
}

export function getRideStatus(ride) {
    if (!ride.legs) {
        return 'UNKNOWN';
    }
    if (ride.status === 'CANCELED') {
        return 'CANCELED';
    }
    const nowDateTime = new Date().getTime();
    let legNotStarted = ride.legs.length;
    for (const leg of ride.legs) {
        const pickUpDateTime = new Date(leg.pickUpDateTime).getTime();
        if (pickUpDateTime < nowDateTime) {
            legNotStarted--;
        }
    }
    if (legNotStarted == ride.legs.length) {
        return 'NOT STARTED';
    } else if (legNotStarted === 0) {
        return 'COMPLETED';
    } else {
        return 'IN PROGRESS';
    }
}

export function getServiceLevel(serviceLevel) {
    if (serviceLevel === SAFE) {
        return 'Safe';
    } else if (serviceLevel === SECURE) {
        return 'Secure';
    } else if (serviceLevel === ULTRA_SECURE) {
        return 'Ultra-Secure';
    } else {
        return serviceLevel;
    }
}

export function setUpPhoneNumber(old, value) {
    if ((old === null || old === '') && value.length >= 10) {
        return `${value.replace('+', '+1')}`;
    }
    return value;
}

export function printDate(dateString) {
   /*const date =  new Date(dateString);
   if (isNaN(date)) {
    return 'N/A';
   }
   return date.toLocaleString('en-US', {
        weekday: 'short',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true});*/
     dayjs.extend(utc);
     dayjs.extend(timezone);
     dayjs.extend(advanced);
     if (isNaN(new Date(dateString))) {
          return 'N/A';
     }
     let timeZone = 'America/Los_Angeles';
     if (dateString.includes('-05:00')) {
          timeZone = 'Jamaica';
     }
     return dayjs(dateString).tz(timeZone).format('MMMM D, YYYY [at] hh:mm A z');
}

export function getPaymentInfo(payment) {
    if ('paymentIntent' in payment) {
        return {id: payment.paymentIntent.id, type: payment.paymentIntent.object, amount: payment.paymentIntent.amount};
    } else {
        return {id: payment.id, type: payment.object, amount: payment.amount};
    }
}

export function getPaymentHistory(paymentConfirm) {
    if (paymentConfirm instanceof Array ) {
        return paymentConfirm;
    } else {
        return [paymentConfirm];
    }
}

export function getVehicleType(passengerCount) {
   if (passengerCount <= MAX_PASSENGERS_CAR_SEATS) {
        passengers_additional_fares = passengerCount - MAX_PASSENGERS_CAR_INCLUDED;
       return 'car';
   } else if (passengerCount <= MAX_PASSENGERS_SUV_SEATS) {
        passengers_additional_fares = passengerCount - MAX_PASSENGERS_SUV_INCLUDED;
       return 'suv';
   } else if (passengerCount <= MAX_PASSENGERS_VAN_SEATS) {
        passengers_additional_fares = passengerCount - MAX_PASSENGERS_VAN_INCLUDED;
       return 'van';
   } else {
       return 'bus';
   }
}

export function getVehicleRateByType(vehicleType, isDaily) {
    let rate = COST_RENTAL_BUS_DAILY_FB;
    if (isDaily)
    {
        switch (vehicleType.toLowerCase()) {
            case 'car':
                rate = COST_RENTAL_CAR_DAILY_FB;
                break;
            case 'suv':
                rate = COST_RENTAL_SUV_DAILY_FB;
                break;
            case 'van':
                rate = COST_RENTAL_VAN_DAILY_FB;
                break;
            default:
                rate = COST_RENTAL_BUS_DAILY_FB;
                //return rate ;
                break;
        }
    }
    else
    {
        switch (vehicleType.toLowerCase()) {
            case 'car':
                rate = COST_RENTAL_CAR_HOURLY_FB;
                break;
            case 'suv':
                rate = COST_RENTAL_SUV_HOURLY_FB;
                break;
            case 'van':
                rate = COST_RENTAL_VAN_HOURLY_FB;
                break;
            default:
                rate = COST_RENTAL_BUS_HOURLY_FB;
                // return rate;
                break;
        }
    }

    return rate;
}

export function getVehicleCountBySelection(vehicleType, passengerCount) {
    let maxPassengers;

    switch (vehicleType.toLowerCase()) {
        case 'car':
            maxPassengers = MAX_PASSENGERS_CAR_SEATS;
            break;
        case 'suv':
            maxPassengers = MAX_PASSENGERS_SUV_SEATS;
            break;
        case 'van':
            maxPassengers = MAX_PASSENGERS_VAN_SEATS;
            break;
        case 'bus':
            maxPassengers = MAX_PASSENGERS_BUS_SEATS;
            break;
        default:
            return 0;
    }

    if (passengerCount <= maxPassengers) {
        return 1;
    } else {
        const fullGroups = Math.floor(passengerCount / maxPassengers);
        const remainder = passengerCount % maxPassengers;

        return fullGroups + (remainder > 0 ? 1 : 0);
    }
}

export function getVehicleCount(vehicleType, passengerCount) {
    let maxPassengers;

    switch (vehicleType.toLowerCase()) {
        case 'car':
            maxPassengers = MAX_PASSENGERS_CAR_SEATS;
            break;
        case 'suv':
            maxPassengers = MAX_PASSENGERS_SUV_SEATS;
            break;
        case 'van':
            maxPassengers = MAX_PASSENGERS_VAN_SEATS;
            break;
        case 'bus':
            maxPassengers = MAX_PASSENGERS_BUS_SEATS;
            break;
        default:
            return 0;
    }

    if (passengerCount <= maxPassengers) {
        return 1;
    } else {
        const fullGroups = Math.floor(passengerCount / maxPassengers);
        const remainder = passengerCount % maxPassengers;

        return fullGroups + (remainder > 0 ? 1 : 0);
    }
}

export function getVehicleRentalDaily(passengerCount) {
    if (passengerCount <= MAX_PASSENGERS_CAR_SEATS) {
        return COST_RENTAL_CAR_DAILY_FB;
    } else if (passengerCount <= MAX_PASSENGERS_SUV_SEATS) {
        return COST_RENTAL_SUV_DAILY_FB;
    } else if (passengerCount <= MAX_PASSENGERS_VAN_SEATS) {
        return COST_RENTAL_VAN_DAILY_FB;
    } else {
        return COST_RENTAL_BUS_DAILY_FB; // Treat as bus for any count above 11
    }
 }

 export function getInitalDate(serviceArea) {
    const jaStartDate = dayjs.tz(LAUNCH_DATE_REGION_JAM, 'America/Los_Angeles');
    const sacStartDate = dayjs.tz(LAUNCH_DATE_REGION_SAC, 'America/Los_Angeles');
    const abqStartDate = dayjs.tz(LAUNCH_DATE_REGION_ABQ, 'America/Los_Angeles');

    const today = dayjs();

    if (serviceArea === 'ja') {
        return jaStartDate.isBefore(today) ? today: jaStartDate;
    } else if (serviceArea === 'sac') {
       return sacStartDate.isBefore(today) ? today: sacStartDate;
    } else if (serviceArea === 'abq') {
       return abqStartDate.isBefore(today) ? today: abqStartDate;
    } else {
       return today;
    }
 }

export async function hasRyderUsedCode(db, ryderId, code) {
    const bookedRideDoc = await getDocs(query(collection(db, BOOKED_RIDES_TABLE), where('ryder', '==', ryderId), where('code', '==', code)));
    if (bookedRideDoc.docs.length >= 1) {
        return true;
    }
    return false;
}

 export async function  checkCode(db, codeInfo, serviceArea, ryderId) {
    if (!ryderId) {
        return 'Login to use promo code.';
    }
    if (!codeInfo || !codeInfo?.type) {
        return 'Promo code not found.';
    }
    if (codeInfo?.region && codeInfo?.region !== serviceArea) {
        return 'Promo is not available in this region.';
    }
    if (codeInfo?.expiration) {
        const expirationDate = dayjs(codeInfo?.expiration);
        const today = dayjs();
        if (expirationDate.isBefore(today)) {
            return 'Promo code has expired.';
        }
    }
    const used = codeInfo?.used ? parseInt(codeInfo?.used): 0;
    const quantity = codeInfo?.quantity ? parseInt(codeInfo?.quantity): 0;
    if (quantity !== 0 && used >= quantity) {
        return 'Promo code has reached max.'
    }

    if (codeInfo?.singleUse) {
        const codeUsed = await hasRyderUsedCode(db, ryderId, codeInfo.code);
        if (codeUsed) {
            return 'Promo code already used.'
        }
    }
    return null;
 }