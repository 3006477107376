import './Entity.css';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { getFirestore, addDoc, collection, doc, getDocs, runTransaction, setDoc } from "firebase/firestore";

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { FirebaseContext } from '../providers/FirebaseContext.js';

import { ENTITIES_TABLE } from '../libs/constants.js';

function Entity(props) {
    const editMode = props?.edit || false;
    const buttonTitle = editMode ? 'Update Entity' : 'Add Entity';

    // Code Information
    const [name, setName] = useState(props?.entity?.name || null);
    const [type, setType] = useState(props?.entity?.type || null);
    const [description, setDescription] = useState(props?.entity?.description || null);

    const {firebaseApp, setFirebaseApp} = useContext(FirebaseContext);

    const nav = useNavigate();
    const db = getFirestore(firebaseApp);

    const submit = () => {
        const entity = {
            name: name,
            type: type,
            description: description,
        };
        if (editMode) {
            const entityRef = doc(db, ENTITIES_TABLE, props.entity.id);
            setDoc(entityRef, entity,{ merge: true }).then(() => {
                if (props.onAction) {
                    props.onAction();
                } else {
                    nav('/entities');
                }
              }, (err) => {
                console.error(err);
              });
         } else {
            addDoc(collection(db, ENTITIES_TABLE), entity).then(() => {
                 if (props.onAction) {
                     props.onAction();
                 } else {
                     nav('/entities');
                 }
               }, (err) => {
                 console.error(err);
               });
         }
    };

    return (
        <div className="Entity">
            <Stack direction="column" spacing={2}>
                <div className="EntityTitle"><Button onClick={() => props.onBack() }><ArrowBackIcon /></Button> {editMode ? 'Edit': 'Add' } Entity</div>
                <h2>Entity Information</h2>
                <TextField label="Name" value={name} onChange={(event) => setName(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <Box sx={{ minWidth: 120, maxWidth: 300 }}>
                   <FormControl fullWidth>
                       <InputLabel>Type</InputLabel>
                       <Select
                         value={type}
                         onChange={(event) => { setType(event.target.value); }}
                         label="Type"
                       >
                            <MenuItem value={null}><em>None</em></MenuItem>
                            <MenuItem value={"corporate"}>Corporate</MenuItem>
                            <MenuItem value={"partner"}>Partner</MenuItem>
                            <MenuItem value={"sales"}>Sales</MenuItem>

                       </Select>
                   </FormControl>
                </Box>
                <TextField minRows="10" value={description} onChange={(event) => { setDescription(event.target.value); }} aria-label="Description" label="Description"  multiline/>
                <div className="EntityButton">
                    <Button variant="contained" onClick={submit}>{buttonTitle}</Button>
                </div>
            </Stack>
        </div>
    );
}

export default Entity;
