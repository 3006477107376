import './Partners.css';
import React, {useContext, useState, useEffect, useMemo} from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

import { getFirestore, query, collection, where, getDocs } from "firebase/firestore";

import { PARTNERS_TABLE } from '../libs/constants.js';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

import Partner from '../components/Partner';

function Partners () {
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const [loaded, setLoaded] = useState(false);
    const [partners, setPartners] = useState([]);
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [orderBy, setOrderBy] = useState('make');
    const [order, setOrder] = useState('desc');
    const [mode, setMode] = useState(null);
    const [selectedPartner, setSelectedPartner] = useState(null);

    const {auth, setAuth} = useContext(AuthContext);
    const {firebaseApp, setFirebaseApp} = useContext(FirebaseContext);

    const nav = useNavigate();
    const db = getFirestore(firebaseApp);

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const fetchPartners = async () => {
      getDocs(collection(db, PARTNERS_TABLE))
              .then((querySnapshot)=>{
                   const newPartners = querySnapshot.docs.map((partner) =>
                            ({...partner.data(), id: partner.id}));
                  setPartners(newPartners);
                  setLoaded(true);
              })
    };

    useEffect(()=>{
        fetchPartners();
    }, []);

    useEffect(() => {
        function handleResize() {
            setViewPortWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
    });

    const filterRows = (rows) => {
      if (filter === null) {
          return rows;
      }
      return rows.filter((row) => {
          const name = row?.name || 'N/A';
          const serviceArea = row?.serviceArea || 'N/A';
          const filterLowerCase = filter.toLowerCase();
          return (name.toLowerCase().includes(filterLowerCase)||
                  serviceArea.toLowerCase().includes(filterLowerCase));
      });
    };

    const sortRows = (rows) => {
      return rows.sort((a, b) =>  {
          if (orderBy === 'name') {
              if (order === 'asc') {
                  return a.name.localeCompare(b.name);
              } else {
                  return b.name.localeCompare(a.name);
              }
          } else if (orderBy === 'serviceArea') {
              if (order === 'asc') {
                  return a.serviceArea.localeCompare(b.serviceArea);
              } else {
                  return b.serviceArea.localeCompare(a.serviceArea);
              }
          } else if (orderBy === 'longitudeWest') {
            if (order === 'asc') {
                return a.longitudeWest.localeCompare(b.longitudeWest);
            } else {
                return b.longitudeWest.localeCompare(a.longitudeWest);
            }
          } else if (orderBy === 'longitudeEast') {
              if (order === 'asc') {
                  return a.longitudeEast.localeCompare(b.longitudeEast);
              } else {
                  return b.longitudeEast.localeCompare(a.longitudeEast);
              }
          } else if (orderBy === 'latitudeNorth') {
            if (order === 'asc') {
              return a.latitudeNorth.localeCompare(b.latitudeNorth);
            } else {
              return b.latitudeNorth.localeCompare(a.latitudeNorth);
            }
         } else if (orderBy === 'latitudeSouth') {
            if (order === 'asc') {
                return a.latitudeSouth.localeCompare(b.latitudeSouth);
            } else {
                return b.latitudeSouth.localeCompare(a.latitudeSouth);
            }
          } else {
              return 0;
          }

      })
    };

    const filteredRows = useMemo(() => filterRows(sortRows(partners)), [partners, filter, order, orderBy]);

    const visibleRows = useMemo(
        () => filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [page, rowsPerPage, filteredRows, filter, order, orderBy],
      );

    const onColumnClick = (header) => {
      if (header === orderBy) {
          setOrder(order !== 'asc' ? 'asc': 'desc');
      } else {
          setOrderBy(header);
          setOrder('asc');
      }
    };

    if (selectedPartner && mode === 'edit') {
            return (
                  <>
                      <Partner partner={selectedPartner} onBack={() => {setSelectedPartner(null); setMode(null);}} onAction={() => {setSelectedPartner(null); setMode(null); fetchPartners();} } edit={true} />
                  </>
              );
    } else if (mode === 'add') {
            return (
                  <>
                      <Partner onBack={() => {setSelectedPartner(null); setMode(null);}} onAction={() => {setSelectedPartner(null); setMode(null); fetchPartners();} } edit={false} />
                  </>
              );
    }


    if (visibleRows.length === 0 && !loaded) {
      return (<div className="Loading"><CircularProgress/></div>);
    }

    return ( <div className="Partners">
        <div className="PartnersButton">
          <TextField label="Search" variant="outlined" type="text" value={filter} size={"small"} onChange={(event) => {setFilter(event.target.value)}}/>
          &nbsp;<Button variant="outlined" onClick={() => {setMode('add');} }>Add Partner</Button>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key={'name'}><TableSortLabel active={orderBy === 'name'} onClick={() => {onColumnClick('name'); } } direction={orderBy === 'name' ? order : 'asc'}>Name</TableSortLabel></TableCell>
              <TableCell key={'serviceArea'}><TableSortLabel active={orderBy === 'serviceArea'} onClick={() => {onColumnClick('serviceArea');} } direction={orderBy === 'serviceArea'? order : 'asc'}>Service Area</TableSortLabel></TableCell>
              <TableCell key={'longitudeWest'}><TableSortLabel active={orderBy === 'longitudeWest'} onClick={() => {onColumnClick('longitudeWest');} } direction={orderBy === 'longitudeWest'? order : 'asc'}>Longitude West</TableSortLabel></TableCell>
              <TableCell key={'longitudeEast'}><TableSortLabel active={orderBy === 'longitudeEast'} onClick={() => {onColumnClick('longitudeEast');} } direction={orderBy === 'longitudeEast'? order : 'asc'}>Longitude East</TableSortLabel></TableCell>
              <TableCell key={'latitudeNorth'}><TableSortLabel active={orderBy === 'latitudeNorth'} onClick={() => {onColumnClick('latitudeNorth');} } direction={orderBy === 'latitudeNorth'? order : 'asc'}>Latitude North</TableSortLabel></TableCell>
              <TableCell key={'latitudeSouth'}><TableSortLabel active={orderBy === 'latitudeSouth'} onClick={() => {onColumnClick('latitudeSouth');} } direction={orderBy === 'latitudeSouth'? order : 'asc'}>Latitude South</TableSortLabel></TableCell>
              <TableCell key={'actions'}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
                visibleRows.map( (partner,i) => {
                  const keyName = `${partner.name}_${i}`;
                  return (
                    <TableRow key={keyName}>
                      <TableCell>{partner.name}</TableCell>
                      <TableCell>{partner.serviceArea}</TableCell>
                      <TableCell>{partner.longitudeWest}</TableCell>
                      <TableCell>{partner.longitudeEast}</TableCell>
                      <TableCell>{partner.latitudeNorth}</TableCell>
                      <TableCell>{partner.latitudeSouth}</TableCell>
                      <TableCell><Button onClick={() => {setMode('edit'); setSelectedPartner(partner)}}><EditIcon /></Button></TableCell>
                    </TableRow>
                  );
                })
            }
            </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>);

  }

export default Partners;
