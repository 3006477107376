import './RideLeg.css';
import blackCar from './images/black-white-car.png';
import blackSuv from './images/black-white-suv.png';
import blackVan from './images/black-white-van.png';
import blackBus from './images/black-white-bus.png';
import React, { useContext, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { getFirestore, addDoc, collection, getDocs } from "firebase/firestore";

import {Map, Marker, useMap, useMapsLibrary} from '@vis.gl/react-google-maps';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';

import AutoCompleteTextField from './AutoCompleteTextField';
import { VIEWPORT_LIMIT, MAX_PASSENGERS, CARS_TABLE, DRIVERS_TABLE } from '../libs/constants.js';
import { MAX_BAGS, MAX_NON_STANDARD_BAGS,
         MULTIPLIER_MAX_PASSENGERS, MULTIPLIER_MAX_PASSENGERS_ORIGINAL, ROUND_TRIP, ONE_WAY, HOURLY,
         MIN_FARE_DRIVER_SAFE } from '../libs/constants.js';
import { getVehicleType, getInitalDate, getVehicleCountBySelection, getVehicleCount, getVehicleRateByType } from '../libs/utils.js';

import { FirebaseContext } from '../providers/FirebaseContext.js';

const routeService = { current: null };

function RideLeg(props) {
    const disabledFeature = true;
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(advanced);
    const [rideLegInfo, setRideLegInfo] = useState({});
    const timeZone = props.region === 'ja'? 'Jamaica': 'America/Los_Angeles';
    const timeZoneAbbrev = dayjs().tz(timeZone).format('z');
    const initialDay = props?.leg?.pickUpDateTime ?  dayjs(props?.leg?.pickUpDateTime).tz(timeZone) : dayjs().add(1, 'day').tz(timeZone);
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const [pickUp, setPickUp] = useState(props?.leg?.from || null);
    const [dropOff, setDropOff] = useState(props?.leg?.to || null);
    const [pickUpPlaceId, setPickUpPlaceId] = useState(props?.leg?.fromPlaceId || null);
    const [dropOffPlaceId, setDropOffPlaceId] = useState(props?.leg?.toPlaceId || null);
    const [date, setDate] = useState(initialDay);
    const [time, setTime] = useState(initialDay);
    // Round trip
    const returnInitialDay = props?.leg?.returnPickUpDateTime ?  dayjs(props?.leg?.returnPickUpDateTime).tz(timeZone) : dayjs().add(1, 'day').tz(timeZone);
    const [returnPickUp, setReturnPickUp] = useState(props?.leg?.returnFrom || null);
    const [returnDropOff, setReturnDropOff] = useState(props?.leg?.returnTo || null);
    const [returnPickUpPlaceId, setReturnPickUpPlaceId] = useState(props?.leg?.returnFromPlaceId || null);
    const [returnDropOffPlaceId, setReturnDropOffPlaceId] = useState(props?.leg?.returnToPlaceId || null);
    const [returnDate, setReturnDate] = useState(returnInitialDay);
    const [returnTime, setReturnTime] = useState(returnInitialDay);

    const vehicleCount = getVehicleCountBySelection(props?.leg?.vehicleType || 'car', props?.leg?.passengers || 1);
    const [passengers, setPassengers] = useState(props?.leg?.passengers || 1);
    const [bags, setBags] = useState(props?.leg?.bags?.luggage || 1);
    const [overSizedBags, setOverSizedBags] = useState(props?.leg?.bags?.overSizedBags || 0);
    const [irregularBags, setIrregularBags] = useState(props?.leg?.bags?.irregularBags || 0);
    const [allDay, setAllDay] = useState(props?.leg?.allDay || false);
    const [driverId, setDriverId] = useState(props?.leg?.driverId || null);
    const [carId, setCarId] = useState(props?.leg?.carId || null);
    const [driverIds, setDriverIds] = useState(props?.leg?.driverIds || []);
    const [carIds, setCarIds] = useState(props?.leg?.carIds || []);
    const [returnDriverIds, setReturnDriverIds] = useState(props?.leg?.returnDriverIds || []);
    const [returnCarIds, setReturnCarIds] = useState(props?.leg?.returnCarIds || []);
    const [returnDriverId, setReturnDriverId] = useState(props?.leg?.returnDriverId || null);
    const [returnCarId, setReturnCarId] = useState(props?.leg?.returnCarId || 'driver');
    const [drivers, setDrivers] = useState([]);
    const [cars, setCars] = useState([]);

    const [passengersAnchor, setPassengersAnchor] = useState(null);

    const [requestedVehicleType, setRequestedVehicleType] = useState(props?.leg?.vehicleType || 'car');
    const [calcVehicleType, setCalcVehicleType] = useState(props?.leg?.vehicleType);

    const [calcVehicleCount, setCalcVehicleCount] = useState(vehicleCount);
    const [currentVehicleIndex, setCurrentVehicleIndex] = useState(0);
    const [vehicleType, setVehicleType] = useState(props?.leg?.vehicleType || 'car');
    const [vehicleRate, setVehiclerate] = useState(getVehicleRateByType(requestedVehicleType, false)); // todo:eahj, fix, compud issue should be about $55.. not $110 for 15 mins,...useState(Math.max(getVehicleRateByType(vehicleType, false), MIN_FARE_DRIVER_SAFE));

    const tripType = props?.tripType || null;

    const {firebaseApp} = useContext(FirebaseContext);
    const db = getFirestore(firebaseApp);

    const handlePassengersClick = (event) => {
        setPassengersAnchor(passengersAnchor ? null : event.currentTarget);
    };

    const passengersOpen = Boolean(passengersAnchor);
    const passengersId = passengersOpen ? 'passengers-popper' : undefined;

    const [bagsAnchor, setBagsAnchor] = useState(null);
    const [vehicleAnchor, setVehicleAnchor] = useState(null);

    const vehicleOpen = Boolean(vehicleAnchor);
    const vehicleId = vehicleOpen ? 'vehicle-popper' : undefined;

    const [estimatedDuration, setEstimatedDuration] = useState(props?.leg?.estimatedDuration);
    const [returnEstimatedDuration, setReturnEstimatedDuration] = useState(props?.leg?.returnEstimatedDuration);
    const map = useMap();
    const routeLib = useMapsLibrary('routes');

    const handleBagsClick = (event) => {
        setBagsAnchor(bagsAnchor ? null : event.currentTarget);
    };

    const updateEstimatedDuration = () => {
        if (!routeLib) {
            return;
        }

        if (!routeService.current) {
            routeService.current = new routeLib.DistanceMatrixService();
        }

        if (!routeService.current) {
            return;
        }

        if (dropOffPlaceId === null || pickUpPlaceId === null ||
           dropOffPlaceId === 'null' || pickUpPlaceId === 'null' ||
           dropOffPlaceId === undefined || pickUpPlaceId === undefined) {
            setEstimatedDuration({});
            onChange({updatedEstimatedDuration: {}});
            return;
        }

        routeService.current.getDistanceMatrix({
            destinations: [{placeId: dropOffPlaceId}],
            origins: [{placeId: pickUpPlaceId}],
            travelMode: routeLib.TravelMode.DRIVING}, (response, status) => {
                const duration = response.rows[0].elements[0].duration;
                setEstimatedDuration(duration);
                onChange({updatedEstimatedDuration: duration});
            });

    };

    const updateReturnEstimatedDuration = () => {
            if (!routeLib) {
                return;
            }

            if (!routeService.current) {
                routeService.current = new routeLib.DistanceMatrixService();
            }

            if (!routeService.current) {
                return;
            }

            if (returnDropOffPlaceId === null || returnPickUpPlaceId === null ||
               returnDropOffPlaceId === 'null' || returnPickUpPlaceId === 'null' ||
               returnDropOffPlaceId === undefined || returnPickUpPlaceId === undefined) {
                setReturnEstimatedDuration({});
                onChange({updatedReturnEstimatedDuration: {}});
                return;
            }
            const departureDateTime = dayjs.tz(`${returnDate.year()}/${returnDate.month() + 1}/${returnDate.date()} ${returnTime.hour()}:${returnTime.minute()}`, props?.region === 'ja'? 'Jamaica': 'America/Los_Angeles').format();
            const departureTime = new Date(departureDateTime);
            routeService.current.getDistanceMatrix({
                destinations: [{placeId: returnDropOffPlaceId}],
                origins: [{placeId: returnPickUpPlaceId}],
                transitOptions: {departureTime: departureTime},
                travelMode: routeLib.TravelMode.DRIVING}, (response, status) => {
                    const duration = response.rows[0].elements[0].duration;
                    setReturnEstimatedDuration(duration);
                    onChange({updatedReturnEstimatedDuration: duration});
                });

        };

    const bagsOpen = Boolean(bagsAnchor);
    const bagsId = bagsOpen ? 'bags-popper' : undefined;

    const getRideLegInfo = (updated) => {
        return {
            // One-way or initial trip
            from: updated?.updatedFrom || pickUp,
            fromPlaceId: updated?.updatedFromPlaceId || pickUpPlaceId,
            to: updated?.updatedTo || dropOff,
            toPlaceId: updated?.updatedToPlaceId || dropOffPlaceId,
            date: updated?.updatedDate || date,
            time: updated?.updatedTime || time,
            // Round-trip
            returnFrom: updated?.updatedReturnFrom || returnPickUp,
            returnFromPlaceId: updated?.updatedReturnFromPlaceId || returnPickUpPlaceId,
            returnTo: updated?.updatedReturnTo || returnDropOff,
            returnToPlaceId: updated?.updatedReturnToPlaceId || returnDropOffPlaceId,
            returnDate: updated?.updatedReturnDate || returnDate,
            returnTime: updated?.updatedReturnTime || returnTime,

            passengers: updated?.updatedPassengers || passengers,
            vehicleRate: updated?.updatedVehicleRate || vehicleRate,
            vehicleCount: updated?.updatedVehicleCount || getVehicleCountBySelection(requestedVehicleType, passengers),
            vehicleType: updated?.updatedVehicleType || requestedVehicleType,
            bags: {
                luggage: updated?.updatedBags || bags,
                overSizedBags: updated?.updatedOverSizedBags || overSizedBags,
                irregularBags: updated?.updatedIrregularBags || irregularBags
                },
            allDay: ('updatedAllDay' in updated) ? updated.updatedAllDay : allDay,
            estimatedDuration: updated?.updatedEstimatedDuration ||estimatedDuration,
            returnEstimatedDuration: updated?.updatedReturnEstimatedDuration || returnEstimatedDuration,
            driverId: updated?.updatedDriverId || driverId,
            carId: updated?.updatedCarId || carId,
            driverIds: updated?.updatedDriverIds || driverIds,
            carIds: updated?.updatedCarIds || carIds,
            returnDriverId: updated?.updatedReturnDriverId || returnDriverId,
            returnCarId: updated?.updatedReturnCarId || returnCarId,
            returnDriverIds: updated?.updatedReturnDriverIds || returnDriverIds,
            returnCarIds: updated?.updatedReturnCarIds || returnCarIds,
            };
    };

    const onChange = (updated) => {
        if (props.onChange) {
            const newRideLegInfo = getRideLegInfo(updated);
            setRideLegInfo(newRideLegInfo);
            props.onChange(newRideLegInfo);
        }
    };

    const onChangeTime = (value) => {
        if (value) {
          const newTime = value.tz(timeZone);
          setTime(newTime);
          onChange({updatedTime: newTime});
        }
    };

    const onChangeDate = (value) => {
        if (value) {
          const newDate = value.tz(timeZone);
          setDate(newDate);
          onChange({updatedDate: newDate});
        }
    };

    const onChangeReturnTime = (value) => {
        if (value) {
          const newTime = value.tz(timeZone);
          setReturnTime(newTime);
          onChange({updatedReturnTime: newTime});
        }
    };

    const onChangeReturnDate = (value) => {
        if (value) {
          const newDate = value.tz(timeZone);
          setReturnDate(newDate);
          onChange({updatedReturnDate: newDate});
        }
    };

    const incPassengers = () => {
        if (passengers < MAX_PASSENGERS) {
            const newPassengerCount = passengers + 1;
            setPassengers(newPassengerCount);
            const newCalcVehicleCount = getVehicleCountBySelection(vehicleType, newPassengerCount);
            setCalcVehicleCount(newCalcVehicleCount);
            onChange({updatedPassengers: newPassengerCount, updatedVehicleCount:  newCalcVehicleCount });
        }
    };

    const decPassengers = () => {
        if (passengers > 1) {
            const newPassengerCount = passengers - 1;
            setPassengers(newPassengerCount);
            const newCalcVehicleCount = getVehicleCountBySelection(vehicleType, newPassengerCount);
            setCalcVehicleCount(newCalcVehicleCount);
            onChange({updatedPassengers: newPassengerCount,  updatedVehicleCount: newCalcVehicleCount });
        }
    };

    const incBags = () => {
        if (bags < MAX_BAGS) {
            setBags(bags + 1);
            onChange({updatedBags: bags + 1});
        }
    };

    const decBags = () => {
        if (bags > 0) {
            setBags(bags - 1);
            onChange({updatedBags: bags - 1});
        }
    };

    const incOverSizeBags = () => {
        if (overSizedBags < MAX_NON_STANDARD_BAGS) {
            setOverSizedBags(overSizedBags + 1);
            onChange({updatedOverSizedBags: overSizedBags + 1});
        }
    };

    const decOverSizeBags = () => {
        if (overSizedBags > 0) {
            setOverSizedBags(overSizedBags - 1);
            onChange({updatedOverSizedBags: overSizedBags - 1});
        }
    };

   const incIrregularBags = () => {
           if (irregularBags < MAX_NON_STANDARD_BAGS) {
               setIrregularBags(irregularBags + 1);
               onChange({updatedIrregularBags: irregularBags + 1});
           }
       };

       const decIrregularBags = () => {
           if (irregularBags > 0) {
               setIrregularBags(irregularBags - 1);
               onChange({updatedIrregularBags: irregularBags - 1});
           }
       };

       const setFrom = (from) => {
           setPickUp(from);
           if (tripType === ROUND_TRIP) {
               setReturnDropOff(from);
               onChange({updatedFrom: from, updatedReturnTo: from});
           } else {
               onChange({updatedFrom: from});
           }
       };

       const setTo = (to) => {
           setDropOff(to);
           if (tripType === ROUND_TRIP) {
               setReturnPickUp(to);
               onChange({updatedTo: to, updatedReturnFrom: to});
           } else {
               onChange({updatedTo: to});
           }
       };

       const setFromPlaceId = (placeId) => {
           setPickUpPlaceId(placeId);
           if (tripType === ROUND_TRIP) {
               setReturnDropOffPlaceId(placeId);
               onChange({updatedFromPlaceId: placeId, updatedReturnToPlaceId: placeId});
           } else {
               onChange({updatedFromPlaceId: placeId});
           }
       };

       const setToPlaceId = (placeId) => {
           setDropOffPlaceId(placeId);
           if (tripType === ROUND_TRIP) {
               setReturnPickUpPlaceId(placeId);
               onChange({updatedToPlaceId: placeId, updatedReturnFromPlaceId: placeId});
           } else {
               onChange({updatedToPlaceId: placeId});
           }
       };

       const setReturnFromPlaceId = (placeId) => {
           setReturnPickUpPlaceId(placeId);
           onChange({updatedReturnFromPlaceId: placeId});
       };

       const setReturnToPlaceId = (placeId) => {
           setReturnDropOffPlaceId(placeId);
           onChange({updatedReturnToPlaceId: placeId});
       };

       const getVehicleImage = () => {
           return vehicleTypes[currentVehicleIndex].image;
       };

       const getVehicleTypeImage = (vehicleType) => {
           if (vehicleType === 'car') {
               return blackCar;
           } else if (vehicleType === 'suv') {
               return blackSuv;
           } else if (vehicleType === 'van') {
               return blackVan;
           } else if (vehicleType === 'bus') {
               return blackBus;
           }
       };

        const updateVehicleTypeInfo = (newVehicleType) => {
            const updatedVehicleCount = getVehicleCountBySelection(newVehicleType, passengers);
            const updatedVehicleRate = getVehicleRateByType(newVehicleType, false);

            setRequestedVehicleType(newVehicleType);
            setCalcVehicleCount(updatedVehicleCount);

            setVehicleType(newVehicleType);
            setVehiclerate(updatedVehicleRate);

            onChange({ updatedVehicleCount:  updatedVehicleCount, updatedVehicleType: newVehicleType, updatedVehicleRate: updatedVehicleRate });
        };

        const handleVehicleTypeChange = (newVehicleType) => {
            updateVehicleTypeInfo(newVehicleType);
            setVehicleAnchor(null);
        };
       const getCurrentVehicleTypeImage = () => {
           return vehicleTypes[currentVehicleIndex].image;
       };

       const vehicleTypes = [
           { type: 'car', image: blackCar },
           { type: 'suv', image: blackSuv },
           { type: 'van', image: blackVan },
           { type: 'bus', image: blackBus }
       ];

       const handleVehicleTypeClick = () => {
           const nextIndex = (currentVehicleIndex + 1) % vehicleTypes.length;
           setCurrentVehicleIndex(nextIndex);
           const updatedVehicleType = vehicleTypes[nextIndex].type;
           const updatedVehicleCount = getVehicleCountBySelection(updatedVehicleType, passengers);
           const updatedVehicleRate = getVehicleRateByType(updatedVehicleType, false);

           setRequestedVehicleType(updatedVehicleType);
               //getVehicleCount(vehicleType, newPassengerCount)
           setCalcVehicleCount(updatedVehicleCount);
           // eahj, consolidate
           setVehicleType(updatedVehicleType);
           setVehiclerate(updatedVehicleRate);
           restartDriverAndCarIds();
           onChange({ updatedVehicleCount:  updatedVehicleCount, updatedVehicleType: updatedVehicleType, updatedVehicleRate: updatedVehicleRate });
       };

       const handleVehicleClick = (event) => {
           setVehicleAnchor(vehicleAnchor ? null : event.currentTarget);
       };

    const fetchDrivers = async () => {
        getDocs(collection(db, DRIVERS_TABLE))
              .then((querySnapshot)=>{
                  const newData = querySnapshot.docs
                      .map((doc) => ({...doc.data(), id:doc.id }));
                  setDrivers(newData);
              })
    };

    const fetchCars = async () => {
        getDocs(collection(db, CARS_TABLE))
              .then((querySnapshot)=>{
                  const newData = querySnapshot.docs
                      .map((doc) => ({...doc.data(), id:doc.id }))
                      .filter((car) => car.type === vehicleType );
                  setCars(newData);
              })
    };

    const setNewDriverId = (newDriverId, index) => {
        const newDriverIds = [];
        for (let i = 0; i < calcVehicleCount; i++) {
            if (i === index) {
                newDriverIds.push(newDriverId);
            } else {
                newDriverIds.push(driverIds[i]);
            }
        }
        setDriverIds(newDriverIds);
        onChange({updatedDriverIds: newDriverIds});
    };

    const setNewCarId = (newCarId, index) => {
        const newCarIds = [];
        for (let i = 0; i < calcVehicleCount; i++) {
            if (i === index) {
                newCarIds.push(newCarId);
            } else {
                newCarIds.push(carIds[i]);
            }
        }
        setCarIds(newCarIds);
        onChange({updatedCarIds: newCarIds});
    };

    const setNewReturnDriverId = (newDriverId, index) => {
        const newDriverIds = [];
        for (let i = 0; i < calcVehicleCount; i++) {
            if (i === index) {
                newDriverIds.push(newDriverId);
            } else {
                newDriverIds.push(driverIds[i]);
            }
        }
        setReturnDriverIds(newDriverIds);
        onChange({updatedReturnDriverIds: newDriverIds});
    };

    const setNewReturnCarId = (newCarId, index) => {
        const newCarIds = [];
        for (let i = 0; i < calcVehicleCount; i++) {
            if (i === index) {
                newCarIds.push(newCarId);
            } else {
                newCarIds.push(carIds[i]);
            }
        }
        setReturnCarIds(newCarIds);
        onChange({updatedReturnCarIds: newCarIds});
    };

    const checkDriverCar = (driverId) => {
        for (const driver of drivers) {
            if (driver.id === driverId) {
                if (driver?.vehicleInfo?.vehicleType  &&
                    driver?.vehicleInfo?.vehicleType === vehicleType) {
                    return true;
                }
                return false;
            }
        }
        return false;
    };

    const restartDriverAndCarIds = () => {
        const newDriverIds = [];
        const newCarIds = [];
        for (let i = 0; i < calcVehicleCount; i++ ) {
            if (i < driverIds.length ) {
                newDriverIds.push(driverIds[i]);
            } else {
                newDriverIds.push('');
            }
            if (i < carIds.length ) {
                newCarIds.push(carIds[i]);
            } else {
                newCarIds.push('');
            }
        }
        setDriverIds(newDriverIds);
        setCarIds(newCarIds);
    };

    const restartReturnDriverAndCarIds = () => {
        const newDriverIds = [];
        const newCarIds = [];
        for (let i = 0; i < calcVehicleCount; i++ ) {
            if (i < returnDriverIds.length ) {
                newDriverIds.push(returnDriverIds[i]);
            } else {
                newDriverIds.push('');
            }
            if (i < returnCarIds.length ) {
                newCarIds.push(returnCarIds[i]);
            } else {
                newCarIds.push('');
            }
        }
        setReturnDriverIds(newDriverIds);
        setReturnCarIds(newCarIds);
    };

    useEffect(() => {
        restartDriverAndCarIds();
        if (tripType === ROUND_TRIP) {
            restartReturnDriverAndCarIds();
        }
    }, [calcVehicleCount]);

    useEffect(() => {
        fetchDrivers();
    }, []);

    useEffect(() => {
        fetchCars();
    }, [vehicleType]);

    useEffect( () => {
        updateEstimatedDuration();
    }, [pickUpPlaceId, dropOffPlaceId, routeLib]);

    useEffect( () => {
        updateReturnEstimatedDuration();
    }, [returnPickUpPlaceId, returnDropOffPlaceId, routeLib]);

    useEffect( () => {
        if (tripType === ROUND_TRIP) {
            setReturnDropOff(pickUp);
            setReturnPickUp(dropOff);
            setReturnDropOffPlaceId(pickUpPlaceId);
            setReturnPickUpPlaceId(dropOffPlaceId);
            onChange({updatedReturnToPlaceId: pickUpPlaceId,
                      updatedReturnFromPlaceId: dropOffPlaceId,
                      updatedReturnTo: pickUp,
                      updatedReturnFrom: dropOff,});
        } else {
            setReturnDropOff(null);
            setReturnPickUp(null);
            setReturnDropOffPlaceId(null);
            setReturnPickUpPlaceId(null);
            onChange({updatedReturnToPlaceId: null,
                      updatedReturnFromPlaceId: null,
                      updatedReturnTo: null,
                      updatedReturnFrom: null,});
            setReturnEstimatedDuration({});
        }

        }, [tripType]
    );

    useEffect(() => {
        function handleResize() {
            setViewPortWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
    });

    return (
    <div className="RideLeg">
        <Stack spacing={2} direction="column">
            <Stack spacing={4} direction={viewPortWidth > VIEWPORT_LIMIT ? "row": "column"}>
            <div className="PassengerOuter">
                <div className="PassengerLabel">Passengers</div>
                <div className="Passenger">
                    <Button onClick={decPassengers} style={{color: 'black'}}><RemoveIcon/></Button>
                    {passengers}
                    <Button onClick={incPassengers} style={{color: 'black'}}><AddIcon/></Button>
                </div>
            </div>

            <div className="PopupButton">
                <div className="VehicleTypeOuter">
                    <div className="VehicleTypeLabel">Vehicle Type</div>
                    <div className="Vehicle">
                        <span className="SpanLeftBlock">{getVehicleCountBySelection(requestedVehicleType, passengers)} X</span>
                        <span className="VehicleImage"><img src={getVehicleTypeImage(vehicleType)} alt="Vehicle Type" style={{ height: 35, width: 70, marginLeft: 32 }} /></span>
                        <Button onClick={handleVehicleClick} style={{color: 'black'}}>
                            { !vehicleOpen && (<ArrowDropDownIcon />) }
                            { vehicleOpen && (<ArrowDropUpIcon/>)}
                        </Button>
                    </div>
                </div>

                <BasePopup id={vehicleId} open={vehicleOpen} anchor={vehicleAnchor} placement={'bottom-end'}>
                    <ClickAwayListener onClickAway={() => {setVehicleAnchor(null);}}>
                        <div className="Popup">
                            <Stack direction="column">

                                <Button onClick={() => {handleVehicleTypeChange('car');}} className="VehicleTypeButton">
                                    <div className="VehicleTypeDiv">
                                        <span className="SpanLeft">{getVehicleCountBySelection('car', passengers)} X</span>
                                        <span className="VehicleImage"><img src={getVehicleTypeImage('car')} alt="Car" style={{ height: 35, width: 70 }} /></span>
                                     </div>
                                </Button>
                                <Button onClick={() => {handleVehicleTypeChange('suv');}} className="VehicleTypeButton">
                                    <div className="VehicleTypeDiv">
                                        <span className="SpanLeft">{getVehicleCountBySelection('suv', passengers)} X</span>
                                        <span className="VehicleImage"><img src={getVehicleTypeImage('suv')} alt="SUV" style={{ height: 35, width: 70 }} /></span>
                                     </div>
                                </Button>
                                <Button onClick={() => {handleVehicleTypeChange('van');}} className="VehicleTypeButton">
                                    <div className="VehicleTypeDiv">
                                        <span className="SpanLeft">{getVehicleCountBySelection('van', passengers)} X</span>
                                        <span className="VehicleImage"><img src={getVehicleTypeImage('van')} alt="Van" style={{ height: 35, width: 70}} /></span>
                                    </div>
                                </Button>
                                <Button onClick={() => {handleVehicleTypeChange('bus');}} className="VehicleTypeButton">
                                    <div className="VehicleTypeDiv">
                                        <span className="SpanLeft">{getVehicleCountBySelection('bus', passengers)} X</span>
                                        <span className="VehicleImage"><img src={getVehicleTypeImage('bus')} alt="Bus" style={{ height: 35, width: 70}} /></span>
                                    </div>
                                </Button>
                            </Stack>
                        </div>
                    </ClickAwayListener>
                </BasePopup>
            </div>

            <div className="PopupButton">
                <div className="BagsOuter">
                    <div className="BagsLabel">Bags</div>
                    <div className="Bags">
                        <span>{bags + overSizedBags + irregularBags}</span>
                        <Button onClick={handleBagsClick} style={{color: 'black'}}>
                            { !bagsOpen && (<KeyboardArrowDownIcon />) }
                            { bagsOpen && (<KeyboardArrowUpIcon/>)}
                        </Button>
                    </div>
                </div>
                <BasePopup id={bagsId} open={bagsOpen} anchor={bagsAnchor}>
                    <ClickAwayListener onClickAway={() => {setBagsAnchor(null);}}>
                        <div className="Popup">
                            <Stack direction="column">
                                <div>
                                    <div><span className="InfoTitle">Luggage</span><Button onClick={decBags} style={{color: 'black'}}><RemoveIcon/></Button>{bags}<Button onClick={incBags} style={{color: 'black'}}><AddIcon/></Button></div>
                                    <div className="InfoText">Suitcases, Backpacks, etc.</div>
                                </div>
                                <div>
                                    <div><span className="InfoTitle">Oversized</span><Button onClick={decOverSizeBags} style={{color: 'black'}}><RemoveIcon/></Button>{overSizedBags}<Button onClick={incOverSizeBags} style={{color: 'black'}}><AddIcon/></Button></div>
                                    <div className="InfoText">Video Equipment, Boxes, etc.</div>
                                </div>
                                <div>
                                    <div><span className="InfoTitle">Irregular</span><Button onClick={decIrregularBags} style={{color: 'black'}}><RemoveIcon/></Button>{irregularBags}<Button onClick={incIrregularBags} style={{color: 'black'}}><AddIcon/></Button></div>
                                    <div className="InfoText">Golf Clubs, Strollers, etc.</div>
                                </div>
                            </Stack>
                        </div>
                    </ClickAwayListener>
                </BasePopup>
            </div>
            { !disabledFeature && (<div className="AllDay">
                <Checkbox checked={allDay}  onChange={(event) => { setAllDay(event.target.checked); onChange({updatedAllDay: event.target.checked}); }} /> All Day Excursion
            </div>)}

            {estimatedDuration?.text && !returnEstimatedDuration?.text && (<div className="EstimatedDuration"><div class="Inner">Estimated Duration: {estimatedDuration.text}</div></div>)}
            { estimatedDuration?.text && returnEstimatedDuration?.text && (<Stack>
                <div className="EstimatedDuration"><div class="Inner">Estimated Duration: {estimatedDuration.text}</div></div>
                <div className="EstimatedDuration"><div class="Inner">Return Estimated Duration: {returnEstimatedDuration.text}</div></div>
            </Stack>)}
            </Stack>
            <Stack spacing={4} direction={viewPortWidth > VIEWPORT_LIMIT ? "row": "column"}>
                <AutoCompleteTextField key="ride-leg-pickup" label="Pickup Location" variant="outlined" value={pickUp} setValue={setFrom} setPlaceId={setFromPlaceId} region={props.region}/>
                <AutoCompleteTextField key="ride-leg-dropoff" label="Drop-off Location" variant="outlined" value={dropOff} setValue={setTo} setPlaceId={setToPlaceId} region={props.region}/>
                <DatePicker label="Pickup Date" onChange={onChangeDate} value={date} minDate={initialDay}/>
                <TimePicker label={`Pickup Time (${timeZoneAbbrev})`} onChange={onChangeTime} value={time} />
            </Stack>


            {
                [...Array(calcVehicleCount).keys()].map((idOfDriver, i) => {
                    return (
                    <Stack direction="row" spacing={4}>
                        <div>
                            <Box sx={{ minWidth: 250, maxWidth: 300 }}>
                               <FormControl fullWidth>
                                   <InputLabel>Driver</InputLabel>
                                   <Select
                                     value={driverIds[i]}
                                     onChange={(event) => { setNewDriverId(event.target.value, i);}}
                                     label="Driver"
                                   >
                                     <MenuItem value={null}>
                                       <em>None</em>
                                     </MenuItem>
                                     {
                                        drivers.map( (driver, i)  => {
                                            return (<MenuItem value={driver.id}>{driver?.personalInfo?.lastName || 'N/A'}, {driver?.personalInfo?.firstName || 'N/A'}</MenuItem>);
                                        })
                                     }
                                   </Select>
                               </FormControl>
                            </Box>
                        </div>
                        { driverIds[i] !== '' && (<div>
                            <Box sx={{ minWidth: 250, maxWidth: 300 }}>
                               <FormControl fullWidth>
                                   <InputLabel>Car</InputLabel>
                                   <Select
                                     value={carIds[i]}
                                     onChange={(event) => { setNewCarId(event.target.value, i); }}
                                     label="Car"
                                   >
                                    { checkDriverCar(driverIds[i]) && (<MenuItem value={'driver'}>
                                       <em>Driver's Car</em>
                                     </MenuItem>)}
                                     {
                                        cars.map( (car, i)  => {
                                            return (<MenuItem value={car.id}>{car.make}, {car.model}, {car.year}</MenuItem>);
                                        })
                                     }
                                   </Select>
                               </FormControl>
                            </Box>
                        </div>)}
                    </Stack>);
                })
            }
            { tripType === ROUND_TRIP && (<Stack spacing={4} direction={viewPortWidth > VIEWPORT_LIMIT ? "row": "column"}>
                            <TextField label="Reurn Pickup Location" variant="outlined" value={returnPickUp} inputProps={{ readOnly: true }} InputLabelProps={{ shrink: (returnDropOff !== '')}}  sx={{minWidth: 250, maxWidth: 300}}/>
                            <TextField label="Return Drop-off Location" variant="outlined" value={returnDropOff}  inputProps={{ readOnly: true }}  InputLabelProps={{ shrink: (returnPickUp !== '')}}  sx={{minWidth: 250, maxWidth: 300}}/>
                            <DatePicker label="Return Pickup Date" variant="filled" onChange={onChangeReturnDate} value={returnDate} minDate={initialDay}/>
                            <TimePicker label={`Return Pickup Time (${timeZoneAbbrev})`} variant="filled" onChange={onChangeReturnTime} value={returnTime} />
                        </Stack>)}
            { tripType === ROUND_TRIP && (
            <>
                {
                    [...Array(calcVehicleCount).keys()].map((idOfDriver, i) => {
                        return (
                        <Stack direction="row" spacing={4}>
                            <div>
                                <Box sx={{ minWidth: 250, maxWidth: 300 }}>
                                   <FormControl fullWidth>
                                       <InputLabel>Driver (Return)</InputLabel>
                                       <Select
                                         value={returnDriverIds[i]}
                                         onChange={(event) => { setNewReturnDriverId(event.target.value, i);}}
                                         label="Driver (Return)"
                                       >
                                         <MenuItem value={null}>
                                           <em>None</em>
                                         </MenuItem>
                                         {
                                            drivers.map( (driver, i)  => {
                                                return (<MenuItem value={driver.id}>{driver?.personalInfo?.lastName || 'N/A'}, {driver?.personalInfo?.firstName || 'N/A'}</MenuItem>);
                                            })
                                         }
                                       </Select>
                                   </FormControl>
                                </Box>
                            </div>
                            { returnDriverIds[i] !== '' && (<div>
                                <Box sx={{ minWidth: 250, maxWidth: 300 }}>
                                   <FormControl fullWidth>
                                       <InputLabel>Car (Return)</InputLabel>
                                       <Select
                                         value={returnCarIds[i]}
                                         onChange={(event) => { setNewReturnCarId(event.target.value, i); }}
                                         label="Car (Return)"
                                       >
                                        { checkDriverCar(returnDriverIds[i]) && (<MenuItem value={'driver'}>
                                           <em>Driver's Car</em>
                                         </MenuItem>)}
                                         {
                                            cars.map( (car, i)  => {
                                                return (<MenuItem value={car.id}>{car.make}, {car.model}, {car.year}</MenuItem>);
                                            })
                                         }
                                       </Select>
                                   </FormControl>
                                </Box>
                            </div>)}
                        </Stack>);
                    })
                }
             </>)}
        </Stack>
    </div>
    );
}

export default RideLeg;