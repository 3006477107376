export const IS_PROD = process.env.REACT_APP_IS_PROD === '1';

export const SAFE = 'safe';
export const SECURE = 'secure';
export const ULTRA_SECURE = 'ultra-secure';
export const HOURLY_BASE = 70;
export const ONE_WAY = 'one-way';
export const ROUND_TRIP = 'round-trip';
export const HOURLY = 'hourly';

const ENV_PREFIX = IS_PROD ? 'prod/tux' : 'dev/tux';

export const BOOKED_RIDES_TABLE = `${ENV_PREFIX}/BookedRides`;
export const RYDERS_TABLE = `${ENV_PREFIX}/ryders`;
export const ADMINS_TABLE = `${ENV_PREFIX}/admins`;
export const CARS_TABLE = `${ENV_PREFIX}/cars`;
export const DRIVERS_TABLE = `${ENV_PREFIX}/drivers`;
export const CODES_TABLE = `${ENV_PREFIX}/codes`;
export const ENTITIES_TABLE = `${ENV_PREFIX}/entities`;
export const DRIVERS_SCHEDULE_TABLE = `${ENV_PREFIX}/DriverSchedule`;
export const CONFIRMATION_TABLE = `${ENV_PREFIX}/ConfirmationSequence`;
export const PARTNERS_TABLE = `${ENV_PREFIX}/partners`;

export const MAX_PASSENGERS = 1000;
export const MAX_BAGS = 1000;
export const MAX_NON_STANDARD_BAGS = 4;

// USE REGION SPECIFIC BELOW, export const MIN_BASE_FARE = 50;  // TODO:  Use to adjust minimum totalcost
export const DISCOUNT_RATE = .80;
export const DISCOUNT_START_DATE = 20240629;
export const DISCOUNT_END_DATE = 20240708;
export const DISCOUNT_MIN_COST = 40;

export const MAX_PASSENGERS_CAR_SEATS = 4;
export const MAX_PASSENGERS_SUV_SEATS = 7;
export const MAX_PASSENGERS_VAN_SEATS = 11;
export const MAX_PASSENGERS_BUS_SEATS = 20;

// 20240926, to replcae next set below ie INCLUDED
export const PERCENT_COST_ADDITIONAL_SEAT_CAR = .25;
export const PERCENT_COST_ADDITIONAL_SEAT_SUV = .25;
export const PERCENT_COST_ADDITIONAL_SEAT_VAN = .25;
export const PERCENT_COST_ADDITIONAL_SEAT_BUS = .25;

export const MAX_PASSENGERS_CAR_INCLUDED = 3;
export const MAX_PASSENGERS_SUV_INCLUDED = 5;
export const MAX_PASSENGERS_VAN_INCLUDED = 10;
export const MAX_PASSENGERS_BUS_INCLUDED = 20;

export const MAX_PASSENGERS_EXTRA = -1; // USE MAX PASSENGERS for now, ...WE WANT TO PROVIDE ADDITIONAL PASSENGERS AND DISCOURAGE FULL CARS DUE TO DRIVER SAFETY

// export const MAX_PASSENGERS_ORIGINAL = 6;
export const MULTIPLIER_PER_SEAT_COST_OF_BASE_FB = .25; // 20240908 - COST FOR >
export const MULTIPLIER_PER_SEAT_COST_OF_BASE_CAR = .25;
export const MULTIPLIER_PER_SEAT_COST_OF_BASE_SUV = .25;
export const MULTIPLIER_PER_SEAT_COST_OF_BASE_VAN = 1.00;
export const MULTIPLIER_PER_SEAT_COST_OF_BASE_BUS = 1.00;

// TODO:  20240921, couple with realtime traffic index to apply
export const MULTIPLIER_SURGE_LOW = 1.75;
export const MULTIPLIER_SURGE_HIGH = 2.5;

export const MULTIPLIER_REGION_JAM = 1; //.85;
export const MULTIPLIER_REGION_SAC = 1;
export const MULTIPLIER_REGION_ABQ = 1; //.95;

export const SERVICE_LEVEL_SAFE_DAILY_JAM = 800;
export const SERVICE_LEVEL_SECURE_DAILY_JAM = 1600;
export const SERVICE_LEVEL_SAFE_HOURLY_JAM = 200;
export const SERVICE_LEVEL_SECURE_HOURLY_JAM = 350;

export const SERVICE_LEVEL_SAFE_DAILY_SAC = 1200;
export const SERVICE_LEVEL_SECURE_DAILY_SAC = 1800;
export const SERVICE_LEVEL_SAFE_HOURLY_SAC = 200;
export const SERVICE_LEVEL_SECURE_HOURLY_SAC = 350;

export const SERVICE_LEVEL_SAFE_DAILY_ABQ = 1200;
export const SERVICE_LEVEL_SECURE_DAILY_ABQ = 1800;
export const SERVICE_LEVEL_SAFE_HOURLY_ABQ = 200;
export const SERVICE_LEVEL_SECURE_HOURLY_ABQ = 350;

export const MIN_FARE_DRIVER_SAFE = 50;
export const MIN_FARE_DRIVER_SECURE = 50;
export const MIN_FARE_DRIVER_SECURE_DETAIL = 1000;
export const MIN_FARE_DRIVER_COMMERCIAL = 300;

export const COST_DRIVER_SAFE_HOURLY = 100; // 200;
export const COST_DRIVER_SECURE_HOURLY = 150; //350;
export const COST_DRIVER_SECURE_DETAIL_HOURLY = 1000;
export const COST_DRIVER_COMMERCIAL_HOURLY = 400;

// 20240929 SHUTTLE
export const COST_RENTAL_SHUTTLE_HOURLY_FB = 0;
export const COST_RENTAL_SHUTTLE_DAILY_FB = 0;


// TODO:  20240907 - Validate Prices
// Jamaica 20240921, ChatGPT
export const COST_RENTAL_CAR_HOURLY_FB = 20;
export const COST_RENTAL_SUV_HOURLY_FB = 40;
export const COST_RENTAL_VAN_HOURLY_FB = COST_RENTAL_SHUTTLE_HOURLY_FB; //100;
export const COST_RENTAL_BUS_HOURLY_FB = COST_RENTAL_SHUTTLE_HOURLY_FB; // 1000;

export const COST_RENTAL_CAR_HOURLY_JAM = 20;
export const COST_RENTAL_SUV_HOURLY_JAM = 40;
export const COST_RENTAL_VAN_HOURLY_JAM = 60;
export const COST_RENTAL_BUS_HOURLY_JAM = 500;

export const COST_RENTAL_CAR_HOURLY_SAC = 30;
export const COST_RENTAL_SUV_HOURLY_SAC = 60;
export const COST_RENTAL_VAN_HOURLY_SAC = 120;
export const COST_RENTAL_BUS_HOURLY_SAC = 500;

export const COST_RENTAL_CAR_HOURLY_ABQ = 25;
export const COST_RENTAL_SUV_HOURLY_ABQ = 50;
export const COST_RENTAL_VAN_HOURLY_ABQ = 100;
export const COST_RENTAL_BUS_HOURLY_ABQ = 500;


// TODO:  20240908 - Validate
export const COST_RENTAL_CAR_DAILY_FB = 300;
export const COST_RENTAL_SUV_DAILY_FB = 350;
export const COST_RENTAL_VAN_DAILY_FB = 400;
export const COST_RENTAL_BUS_DAILY_FB = 4000;

export const COST_RENTAL_CAR_DAILY_JAM = 80;
export const COST_RENTAL_SUV_DAILY_JAM = 100;
export const COST_RENTAL_VAN_DAILY_JAM = 150;
export const COST_RENTAL_BUS_DAILY_JAM = 300;

export const COST_RENTAL_CAR_DAILY_SAC = 300;
export const COST_RENTAL_SUV_DAILY_SAC = 350;
export const COST_RENTAL_VAN_DAILY_SAC = 400;
export const COST_RENTAL_BUS_DAILY_SAC = 4000;

export const COST_RENTAL_CAR_DAILY_ABQ = 300;
export const COST_RENTAL_SUV_DAILY_ABQ = 350;
export const COST_RENTAL_VAN_DAILY_ABQ = 400;
export const COST_RENTAL_BUS_DAILY_ABQ = 4000;

export const LEAD_TIME_REGION_JAM_HOURS = 2;
export const LEAD_TIME_REGION_SAC_HOURS = 2;
export const LEAD_TIME_REGION_ABQ_HOURS = 0;
export const LEAD_TIME_REGION_FB_HOURS = 0;

export const LAUNCH_DATE_REGION_JAM = '2024-08-01 00:00:00'; //YYYY-MM-DD
export const LAUNCH_DATE_REGION_SAC = '2024-08-01 00:00:00'; //YYYY-MM-DD
export const LAUNCH_DATE_REGION_ABQ = '2024-10-01 00:00:00'; //YYYY-MM-DD

export const COOKIE_ID = 'ppCc';
export const COOKIE_MAX_AGE = 24 * 60 * 60;

export const STRIPE_PK = process.env.REACT_APP_STRIPE_PK;
export const STRIPE_INTENT_URL = process.env.REACT_APP_STRIPE_INTENT_URL;
export const STRIPE_CUSTOMERS_URL = process.env.REACT_APP_STRIPE_CUSTOMERS_URL;
export const STRIPE_REFUND_URL = process.env.REACT_APP_STRIPE_REFUND_URL;

export const VIEWPORT_LIMIT = 800;

export const PHONE = '800.917.5032';
