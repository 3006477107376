import './Entities.css';
import React, {useContext, useState, useEffect, useMemo} from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

import { getFirestore, query, collection, where, getDocs } from "firebase/firestore";

import { ENTITIES_TABLE } from '../libs/constants.js';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

import Entity from '../components/Entity';

function Entities () {
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const [loaded, setLoaded] = useState(false);
    const [entities, setEntities] = useState([]);
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [orderBy, setOrderBy] = useState('code');
    const [order, setOrder] = useState('desc');
    const [mode, setMode] = useState(null);
    const [selectedEntity, setSelectedEntity] = useState(null);

    const {auth, setAuth} = useContext(AuthContext);
    const {firebaseApp, setFirebaseApp} = useContext(FirebaseContext);

    const nav = useNavigate();
    const db = getFirestore(firebaseApp);

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const fetchEntities = async () => {
      getDocs(collection(db, ENTITIES_TABLE))
              .then((querySnapshot)=>{
                   const newEntities = querySnapshot.docs.map((entity) =>
                            ({...entity.data(), id: entity.id}));
                  setEntities(newEntities);
                  setLoaded(true);
              })
    };

    useEffect(()=>{
        fetchEntities();
    }, []);

    useEffect(() => {
        function handleResize() {
            setViewPortWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
    });

    const filterRows = (rows) => {
      if (filter === null) {
          return rows;
      }
      return rows.filter((row) => {
          const name = row?.name || 'N/A';
          const type = row?.type || 'N/A';
          const filterLowerCase = filter.toLowerCase();
          return (name.toLowerCase().includes(filterLowerCase)||
                  type.toLowerCase().includes(filterLowerCase));
      });
    };

    const sortRows = (rows) => {
      return rows.sort((a, b) =>  {
          if (orderBy === 'name') {
              if (order === 'asc') {
                  return a.name.localeCompare(b.name);
              } else {
                  return b.name.localeCompare(a.name);
              }
          } else if (orderBy === 'type') {
              if (order === 'asc') {
                  return a.type.localeCompare(b.type);
              } else {
                  return b.type.localeCompare(a.type);
              }
          } else {
              return 0;
          }

      })
    };

    const filteredRows = useMemo(() => filterRows(sortRows(entities)), [entities, filter, order, orderBy]);

    const visibleRows = useMemo(
        () => filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [page, rowsPerPage, filteredRows, filter, order, orderBy],
      );

    const onColumnClick = (header) => {
      if (header === orderBy) {
          setOrder(order !== 'asc' ? 'asc': 'desc');
      } else {
          setOrderBy(header);
          setOrder('asc');
      }
    };

    if (selectedEntity && mode === 'edit') {
            return (
                  <>
                      <Entity entity={selectedEntity} onBack={() => {setSelectedEntity(null); setMode(null);}} onAction={() => {setSelectedEntity(null); setMode(null); fetchEntities();} } edit={true} />
                  </>
              );
    } else if (mode === 'add') {
            return (
                  <>
                      <Entity onBack={() => {setSelectedEntity(null); setMode(null);}} onAction={() => {setSelectedEntity(null); setMode(null); fetchEntities();} } edit={false} />
                  </>
              );
    }


    if (visibleRows.length === 0 && !loaded) {
      return (<div className="Loading"><CircularProgress/></div>);
    }

    return ( <div className="Entities">
        <div className="EntitiesButton">
          <TextField label="Search" variant="outlined" type="text" value={filter} size={"small"} onChange={(event) => {setFilter(event.target.value)}}/>
          &nbsp;<Button variant="outlined" onClick={() => {setMode('add');} }>Add Entity</Button>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key={'name'}><TableSortLabel active={orderBy === 'name'} onClick={() => {onColumnClick('name'); } } direction={orderBy === 'name' ? order : 'asc'}>Name</TableSortLabel></TableCell>
              <TableCell key={'type'}><TableSortLabel active={orderBy === 'type'} onClick={() => {onColumnClick('type');} } direction={orderBy === 'type'? order : 'asc'}>Type</TableSortLabel></TableCell>
              <TableCell key={'actions'}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
                visibleRows.map( (entity,i) => {
                  const keyName = `${entity.name}_${i}`;
                  return (
                    <TableRow key={keyName}>
                      <TableCell>{entity.name}</TableCell>
                      <TableCell>{entity.type}</TableCell>
                      <TableCell><Button onClick={() => {setMode('edit'); setSelectedEntity(entity)}}><EditIcon /></Button></TableCell>
                    </TableRow>
                  );
                })
            }
            </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>);

  }

export default Entities;