import './Car.css';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { getFirestore, addDoc, collection, doc, getDocs, runTransaction, setDoc } from "firebase/firestore";

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { FirebaseContext } from '../providers/FirebaseContext.js';

import { CARS_TABLE } from '../libs/constants.js';

function Car(props) {
    const editMode = props?.edit || false;
    const buttonTitle = editMode ? 'Update Car' : 'Add Car';

    // Vehicle Information
    const [make, setMake] = useState(props?.car?.make || null);
    const [model, setModel] = useState(props?.car?.model || null);
    const [year, setYear] = useState(props?.car?.year || null);
    const [color, setColor] = useState(props?.car?.color || null);
    const [licensePlate, setLicensePlate] = useState(props?.car?.licensePlate || null);
    const [vin, setVin] = useState(props?.car?.vin || null);
    const [type, setType] = useState(props?.car?.type || null);

    const {firebaseApp, setFirebaseApp} = useContext(FirebaseContext);

    const nav = useNavigate();
    const db = getFirestore(firebaseApp);

    const submit = () => {
        const car = {
            make: make,
            model: model,
            year: year,
            color: color,
            licensePlate: licensePlate,
            vin: vin,
            type: type,
           };
        if (editMode) {
            const carRef = doc(db, CARS_TABLE, props.car.id);
            setDoc(carRef, car,{ merge: true }).then(() => {
                if (props.onAction) {
                    props.onAction();
                } else {
                    nav('/cars');
                }
              }, (err) => {
                console.error(err);
              });
         } else {
            addDoc(collection(db, CARS_TABLE), car).then(() => {
                 if (props.onAction) {
                     props.onAction();
                 } else {
                     nav('/cars');
                 }
               }, (err) => {
                 console.error(err);
               });
         }
    };

    return (
        <div className="Car">
            <Stack direction="column" spacing={2}>
                <div className="CarTitle"><Button onClick={() => props.onBack() }><ArrowBackIcon /></Button> {editMode ? 'Edit': 'Add' } Car</div>
                <h2>Vehicle Information</h2>
                <TextField label="Make" value={make} onChange={(event) => setMake(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <TextField label="Model" value={model} onChange={(event) => setModel(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <TextField label="Year" value={year} onChange={(event) => setYear(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <Box sx={{ minWidth: 120, maxWidth: 300 }}>
                   <FormControl fullWidth>
                       <InputLabel>Vehicle Type</InputLabel>
                       <Select
                         value={type}
                         onChange={(event) => { setType(event.target.value); }}
                         label="Vehicle Type"
                       >
                            <MenuItem value={null}><em>None</em></MenuItem>
                            <MenuItem value={"car"}>Car</MenuItem>
                            <MenuItem value={"suv"}>S.U.V</MenuItem>
                            <MenuItem value={"van"}>Van</MenuItem>
                            <MenuItem value={"bus"}>Bus</MenuItem>
                       </Select>
                   </FormControl>
                </Box>
                <TextField label="Color" value={color} onChange={(event) => setColor(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <TextField label="License Plate" value={licensePlate} onChange={(event) => setLicensePlate(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <TextField label="VIN" value={vin} onChange={(event) => setVin(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />

                <div className="CarButton">
                    <Button variant="contained" onClick={submit}>{buttonTitle}</Button>
                </div>
            </Stack>
        </div>
    );
}

export default Car;
