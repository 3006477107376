import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Outlet } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';

import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import { VIEWPORT_LIMIT } from '../libs/constants.js';
import { getAdminInfo } from '../libs/utils.js';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

function Layout () {
  const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
  const nav =  useNavigate();
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElSignOut, setAnchorElSignOut] = useState(null);

  const {firebaseApp} = useContext(FirebaseContext);
  const authFromApp = getAuth(firebaseApp);

  const {auth, setAuth} = useContext(AuthContext);
  const [authStateComplete, setAuthStateComplete] = useState(false);

  const db = getFirestore(firebaseApp);
  onAuthStateChanged(authFromApp, (user) => {
    if (user && !auth?.loggedIn) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      getAdminInfo(db, user.uid).then((adminInfo) => {
          if (adminInfo != null) {
              setAuth({loggedIn: true, user: user, adminInfo: adminInfo});
          }
          setAuthStateComplete(true);
      });
    } else if (!auth?.loggedIn && location.pathname !== '/signin') {
        nav('/signin');
        setAuthStateComplete(true);
    }

  });

  const openSignOut = Boolean(anchorElSignOut);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenSignOutMenu = (event) => {
    setAnchorElSignOut(event.currentTarget);
  };

  const handleCloseSignOutMenu = () => {
    setAnchorElSignOut(null);
  };

  const clickHome = () => {
    nav('/');
    handleCloseNavMenu();
  };

  const clickRides = () => {
    nav('/rides');
    handleCloseNavMenu();
  };

  const clickDrivers = () => {
     nav('/drivers');
     handleCloseNavMenu();
  };

  const clickCars = () => {
    nav('/cars');
    handleCloseNavMenu();
  };

  const clickCodes = () => {
    nav('/codes');
    handleCloseNavMenu();
  };

  const clickEntities = () => {
    nav('/entities');
    handleCloseNavMenu();
  };

  const clickRyders = () => {
    nav('/ryders');
    handleCloseNavMenu();
  };

  const clickPartners = () => {
    nav('/partners');
    handleCloseNavMenu();
  };

  const clickProfile = () => {
    nav('/profile');
    handleCloseNavMenu();
  };

  const clickSignIn = () => {
    nav('/signin');
  }

  const signOutUser = () => {
    signOut(authFromApp).then(() => {
        setAuth({loggedIn: false, user: null, adminInfo: null});
        nav('/signin');
    });
  }

  useEffect(() => {
    function handleResize() {
        setViewPortWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize)
  });

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
          {!auth?.loggedIn  && (
            <Typography variant="h5" component="div" sx={{ flexGrow: 1}}>
              TUXRydes
            </Typography>)}
            {auth?.loggedIn  && (
              <Box sx={{ flexGrow: 1}}>

                {viewPortWidth > VIEWPORT_LIMIT && (<Typography variant="h5" noWrap component="span">
                  TUXRydes
                </Typography>)}

                <Box display="inline-block" gap={5} sx={{paddingLeft: 10}}>
                  <Button sx= {{color: '#fff'}} onClick={clickRides}>
                    Rides
                  </Button>
                  <Button sx= {{color: '#fff'}} onClick={clickDrivers}>
                    Drivers
                  </Button>
                  <Button sx= {{color: '#fff'}} onClick={clickCars}>
                    Cars
                  </Button>
                  <Button sx= {{color: '#fff'}} onClick={clickRyders}>
                    Ryders
                  </Button>
                  <Button sx= {{color: '#fff'}} onClick={clickCodes}>
                    Codes
                  </Button>
                  <Button sx= {{color: '#fff'}} onClick={clickEntities}>
                    Entities
                  </Button>
                  <Button sx= {{color: '#fff'}} onClick={clickPartners}>
                    Partners
                  </Button>
                </Box>
              </Box>)}

            {!auth?.loggedIn && (
            <Button color="inherit" onClick={clickSignIn}>Sign In</Button>)}
            {auth?.loggedIn && (
                <>
                    <Button
                     id="menu-button"
                     aria-controls={openSignOut ? 'menu' : undefined}
                     aria-haspopup="true"
                     aria-expanded={openSignOut ? 'true' : undefined}
                     style={{color: 'white'}}
                     onClick={handleOpenSignOutMenu}><AccountCircle /></Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElSignOut}
                      open={openSignOut}
                      onClose={handleCloseSignOutMenu}
                      MenuListProps={{
                        'aria-labelledby': 'menu-button',
                      }}
                    >
                      <MenuItem onClick={()=> {signOutUser(); handleCloseSignOutMenu();}}>Sign Out</MenuItem>
                    </Menu>
                </>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      <Outlet />
    </>
  )
}

export default Layout;
