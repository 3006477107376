import './Drivers.css';
import React, {useContext, useState, useEffect, useMemo} from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

import { getFirestore, query, collection, where, getDocs } from "firebase/firestore";

import { DRIVERS_TABLE } from '../libs/constants.js';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

import Driver from '../components/Driver';
import ExcelUpload from '../components/ExcelUpload';
import ShiftScheduler from '../components/ShiftScheduler';

function Drivers () {
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const [loaded, setLoaded] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [orderBy, setOrderBy] = useState('confirm');
    const [order, setOrder] = useState('desc');
    const [mode, setMode] = useState(null);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const {auth, setAuth} = useContext(AuthContext);
    const {firebaseApp, setFirebaseApp} = useContext(FirebaseContext);

    const nav = useNavigate();
    const db = getFirestore(firebaseApp);

    // Sample data for the Shift logic.
    const jsonData = [
      {
        "day": 1,
        "shifts": [
          {
            "shift": "Morning",
            "records": [
              {
                "DriverName": "Kemar Ferguson",
                "UserId": "vZPLZn7xwnecdThZoe1npZQ4MUH1",
                "ArmedStatus": "UnArmed",
                "Region": "Montego Bay"
              },
              {
                "DriverName": "Asife Wright",
                "UserId": "vZPLZn7xwnecdThZoe1npZQ4MUH2",
                "ArmedStatus": "UnArmed",
                "Region": "Montego Bay"
              }
            ]
          },
          {
            "shift": "Afternoon",
            "records": [
              {
                "DriverName": "Seabastina Dryden",
                "UserId": "vZPLZn7xwnecdThZoe1npZQ4MUH3",
                "ArmedStatus": "Armed",
                "Region": "Montego Bay"
              }
            ]
          },
          {
            "shift": "Evening",
            "records": [
              {
                "DriverName": "Mecal Dove",
                "UserId": "vZPLZn7xwnecdThZoe1npZQ4MUH4",
                "ArmedStatus": "Armed",
                "Region": "Montego Bay"
              }
            ]
          }
        ]
      },
      {
        "day": 2,
        "shifts": [
          {
            "shift": "Morning",
            "records": [
              {
                "DriverName": "Kemar Ferguson",
                "UserId": "vZPLZn7xwnecdThZoe1npZQ4MUH1",
                "ArmedStatus": "UnArmed",
                "Region": "Montego Bay"
              },
              {
                "DriverName": "Asife Wright",
                "UserId": "vZPLZn7xwnecdThZoe1npZQ4MUH2",
                "ArmedStatus": "UnArmed",
                "Region": "Montego Bay"
              }
            ]
          },
          {
            "shift": "Afternoon",
            "records": [
              {
                "DriverName": "Seabastina Dryden",
                "UserId": "vZPLZn7xwnecdThZoe1npZQ4MUH3",
                "ArmedStatus": "Armed",
                "Region": "Montego Bay"
              }
            ]
          },
          {
            "shift": "Evening",
            "records": [
              {
                "DriverName": "Mecal Dove",
                "UserId": "vZPLZn7xwnecdThZoe1npZQ4MUH4",
                "ArmedStatus": "Armed",
                "Region": "Montego Bay"
              }
            ]
          }
        ]
      },
      {
        "day": 3,
        "shifts": [
          {
            "shift": "Morning",
            "records": [
              {
                "DriverName": "Driver",
                "UserId": "userId",
                "ArmedStatus": "Armed Status",
                "Region": "Region"
              }
            ]
          },
          {
            "shift": "Afternoon",
            "records": [
              {
                "DriverName": "Driver",
                "UserId": "userId",
                "ArmedStatus": "Armed Status",
                "Region": "Region"
              }
            ]
          },
          {
            "shift": "Evening",
            "records": [
              {
                "DriverName": "Driver",
                "UserId": "userId",
                "ArmedStatus": "Armed Status",
                "Region": "Region"
              }
            ]
          }
        ]
      }
    ];

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const fetchDrivers = async () => {
      getDocs(collection(db, DRIVERS_TABLE))
              .then((querySnapshot)=>{
                   const newDrivers = querySnapshot.docs.map((driver) =>
                            ({...driver.data(), id: driver.id}));
                  setDrivers(newDrivers);
                  setLoaded(true);
              }, (err) => {
                setErrorMessage(err.message);
              })
    };

    useEffect(()=>{
        fetchDrivers();
    }, []);

    useEffect(() => {
        function handleResize() {
            setViewPortWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
    });

    const filterRows = (rows) => {
      if (filter === null) {
          return rows;
      }

      return rows.filter((row) => {
        const firstName = row?.personalInfo?.firstName || 'N/A';
        const lastName = row?.personalInfo?.lastName || 'N/A';
        const email = row?.personalInfo?.email || 'N/A';
        const phoneNumber = row?.personalInfo?.phoneNumber || 'N/A';
        const filterLowerCase = filter.toLowerCase();
        return (firstName.toLowerCase().includes(filterLowerCase) ||
                  lastName.toLowerCase().includes(filterLowerCase) ||
                  email.toLowerCase().includes(filterLowerCase) ||
                  phoneNumber.toLowerCase().includes(filterLowerCase));
      });
    };

    const sortRows = (rows) => {
      return rows.sort((a, b) =>  {
          if (orderBy === 'firstName') {
              if (order === 'asc') {
                  return a.personalInfo.firstName.localeCompare(b.firstName);
              } else {
                  return b.personalInfo.firstName.localeCompare(a.firstName);
              }
          } else if (orderBy === 'lastName') {
              if (order === 'asc') {
                  return a.personalInfo.lastName.localeCompare(b.lastName);
              } else {
                  return b.personalInfo.lastName.localeCompare(a.lastName);
              }
          } else if (orderBy === 'email') {
               if (order === 'asc') {
                   return a.personalInfo.email.localeCompare(b.email);
               } else {
                   return b.personalInfo.email.localeCompare(a.email);
               }
          } else if (orderBy === 'phoneNumber') {
                if (order === 'asc') {
                    return a.personalInfo.phoneNumber.localeCompare(b.phoneNumber);
                } else {
                    return b.personalInfo.phoneNumber.localeCompare(a.phoneNumber);
                }
          } else {
              return 0;
          }

      })
    };

    const filteredRows = useMemo(() => filterRows(sortRows(drivers)), [drivers, filter, order, orderBy]);

    const visibleRows = useMemo(
        () => filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [page, rowsPerPage, filteredRows, filter, order, orderBy],
      );

    const onColumnClick = (header) => {
      if (header === orderBy) {
          setOrder(order !== 'asc' ? 'asc': 'desc');
      } else {
          setOrderBy(header);
          setOrder('asc');
      }
    };

    /*useEffect(() => {
        if (!auth || !auth?.loggedIn) {
          nav('/signin');
        }
    });*/

    if (selectedDriver && mode === 'edit') {
        return (
              <>
                  <Driver driver={selectedDriver} onBack={() => {setSelectedDriver(null); setMode(null);}} onAction={() => {setSelectedDriver(null); setMode(null); fetchDrivers();} } edit={true} />
              </>
          );
    } else if (mode === 'add') {
        return (
              <>
                  <Driver onBack={() => {setSelectedDriver(null); setMode(null);}} onAction={() => {setSelectedDriver(null); setMode(null); fetchDrivers();} } edit={false} />
              </>
          );
    }

    if (visibleRows.length === 0 && !loaded) {
      return (<div className="Loading"><CircularProgress/></div>);
    }

    return ( <div className="Drivers">
        {errorMessage !== '' && (<div className="ErrorMessage">{errorMessage}</div>)}
        <div className="DriversButton">
          <TextField label="Search" variant="outlined" type="text" value={filter} size={"small"} onChange={(event) => {setFilter(event.target.value)}}/>
          &nbsp;<Button variant="outlined" onClick={() => {setMode('add');} }>Add Driver</Button>
          &nbsp;<ShiftScheduler />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key={'firstName'}><TableSortLabel active={orderBy === 'firstName'} onClick={() => {onColumnClick('firstName'); } } direction={orderBy === 'firstName' ? order : 'asc'}>First Name</TableSortLabel></TableCell>
              <TableCell key={'lastName'}><TableSortLabel active={orderBy === 'lastName'} onClick={() => {onColumnClick('lastName');} } direction={orderBy === 'lastName'? order : 'asc'}>Last Name</TableSortLabel></TableCell>
              <TableCell key={'email'}><TableSortLabel active={orderBy === 'email'} onClick={() => {onColumnClick('email');} } direction={orderBy === 'email'? order : 'asc'}>Email</TableSortLabel></TableCell>
              <TableCell key={'phoneNumber'}><TableSortLabel active={orderBy === 'phoneNumber'} onClick={() => {onColumnClick('phoneNumber');} } direction={orderBy === 'phoneNumber'? order : 'asc'}>Phone Number</TableSortLabel></TableCell>
              <TableCell key={'actions'}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
                visibleRows.map( (driver,i) => {
                  const keyName = `${driver.type}_${i}`;
                  return (
                    <TableRow key={keyName}>
                      <TableCell>{driver?.personalInfo?.firstName || 'N/A'}</TableCell>
                      <TableCell>{driver?.personalInfo?.lastName || 'N/A'} </TableCell>
                      <TableCell>{driver?.personalInfo?.email || 'N/A'}</TableCell>
                      <TableCell>{driver?.personalInfo?.phoneNumber || 'N/A'}</TableCell>
                      <TableCell><Button onClick={() => {setMode('edit'); setSelectedDriver(driver)}}><EditIcon /></Button></TableCell>
                    </TableRow>
                  );
                })
            }
            </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>);

  }

export default Drivers;
