import './Codes.css';
import React, {useContext, useState, useEffect, useMemo} from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

import { getFirestore, query, collection, where, getDocs } from "firebase/firestore";

import { CODES_TABLE } from '../libs/constants.js';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

import Code from '../components/Code';

function Codes () {
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const [loaded, setLoaded] = useState(false);
    const [codes, setCodes] = useState([]);
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [orderBy, setOrderBy] = useState('code');
    const [order, setOrder] = useState('desc');
    const [mode, setMode] = useState(null);
    const [selectedCode, setSelectedCode] = useState(null);

    const {auth, setAuth} = useContext(AuthContext);
    const {firebaseApp, setFirebaseApp} = useContext(FirebaseContext);

    const nav = useNavigate();
    const db = getFirestore(firebaseApp);

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const fetchCodes = async () => {
      getDocs(collection(db, CODES_TABLE))
              .then((querySnapshot)=>{
                   const newCodes = querySnapshot.docs.map((code) =>
                            ({...code.data(), id: code.id}));
                  setCodes(newCodes);
                  setLoaded(true);
              })
    };

    useEffect(()=>{
        fetchCodes();
    }, []);

    useEffect(() => {
        function handleResize() {
            setViewPortWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
    });

    const filterRows = (rows) => {
      if (filter === null) {
          return rows;
      }
      return rows.filter((row) => {
          const code = row?.code || 'N/A';
          const type = row?.type || 'N/A';
          const quantity = row?.quantity || 'N/A';
          const used = row?.used || 'N/A';
          const filterLowerCase = filter.toLowerCase();
          return (code.toLowerCase().includes(filterLowerCase)||
                  type.toLowerCase().includes(filterLowerCase) ||
                  quantity.toLowerCase().includes(filterLowerCase) ||
                  used.toLowerCase().includes(filterLowerCase));
      });
    };

    const sortRows = (rows) => {
      return rows.sort((a, b) =>  {
          if (orderBy === 'code') {
              if (order === 'asc') {
                  return a.code.localeCompare(b.code);
              } else {
                  return b.code.localeCompare(a.code);
              }
          } else if (orderBy === 'type') {
              if (order === 'asc') {
                  return a.type.localeCompare(b.type);
              } else {
                  return b.type.localeCompare(a.type);
              }
          } else if (orderBy === 'quantity') {
               if (order === 'asc') {
                   return a.quantity.localeCompare(b.quantity);
               } else {
                   return b.quantity.localeCompare(a.quantity);
               }
          } else if (orderBy === 'used') {
                if (order === 'asc') {
                    return a.used.localeCompare(b.used);
                } else {
                    return b.used.localeCompare(a.used);
                }
          } else {
              return 0;
          }

      })
    };

    const filteredRows = useMemo(() => filterRows(sortRows(codes)), [codes, filter, order, orderBy]);

    const visibleRows = useMemo(
        () => filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [page, rowsPerPage, filteredRows, filter, order, orderBy],
      );

    const onColumnClick = (header) => {
      if (header === orderBy) {
          setOrder(order !== 'asc' ? 'asc': 'desc');
      } else {
          setOrderBy(header);
          setOrder('asc');
      }
    };

    if (selectedCode && mode === 'edit') {
            return (
                  <>
                      <Code code={selectedCode} onBack={() => {setSelectedCode(null); setMode(null);}} onAction={() => {setSelectedCode(null); setMode(null); fetchCodes();} } edit={true} />
                  </>
              );
    } else if (mode === 'add') {
            return (
                  <>
                      <Code onBack={() => {setSelectedCode(null); setMode(null);}} onAction={() => {setSelectedCode(null); setMode(null); fetchCodes();} } edit={false} />
                  </>
              );
    }


    if (visibleRows.length === 0 && !loaded) {
      return (<div className="Loading"><CircularProgress/></div>);
    }

    return ( <div className="Codes">
        <div className="CodesButton">
          <TextField label="Search" variant="outlined" type="text" value={filter} size={"small"} onChange={(event) => {setFilter(event.target.value)}}/>
          &nbsp;<Button variant="outlined" onClick={() => {setMode('add');} }>Add Code</Button>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key={'code'}><TableSortLabel active={orderBy === 'code'} onClick={() => {onColumnClick('code'); } } direction={orderBy === 'code' ? order : 'asc'}>Code</TableSortLabel></TableCell>
              <TableCell key={'type'}><TableSortLabel active={orderBy === 'type'} onClick={() => {onColumnClick('type');} } direction={orderBy === 'type'? order : 'asc'}>Type</TableSortLabel></TableCell>
              <TableCell key={'quantity'}><TableSortLabel active={orderBy === 'quantity'} onClick={() => {onColumnClick('quantity');} } direction={orderBy === 'quantity'? order : 'asc'}>Quantity</TableSortLabel></TableCell>
              <TableCell key={'used'}><TableSortLabel active={orderBy === 'used'} onClick={() => {onColumnClick('used');} } direction={orderBy === 'used'? order : 'asc'}>Used</TableSortLabel></TableCell>
              <TableCell key={'actions'}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
                visibleRows.map( (code,i) => {
                  const keyName = `${code.code}_${i}`;
                  return (
                    <TableRow key={keyName}>
                      <TableCell>{code.code}</TableCell>
                      <TableCell>{code.type}</TableCell>
                      <TableCell>{code.quantity}</TableCell>
                      <TableCell>{code.used}</TableCell>
                      <TableCell><Button onClick={() => {setMode('edit'); setSelectedCode(code)}}><EditIcon /></Button></TableCell>
                    </TableRow>
                  );
                })
            }
            </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>);

  }

export default Codes;
