import './Driver.css';
import React, { useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import MuiPhoneNumber from 'mui-phone-number';

import { getFirestore, addDoc, collection, doc, getDocs, runTransaction, setDoc } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

import { DRIVERS_TABLE } from '../libs/constants.js';

function Driver(props) {
    const editMode = props?.edit || false;
    const buttonTitle = editMode ? 'Update Driver' : 'Add Driver';
    const [errorMessage, setErrorMessage] = useState(null);
    const errorDivRef = useRef(null);
    // Login Information
    //const [userName, setUserName] = useState(props?.driver?.userName || null);
    const [password, setPassword] = useState(props?.driver?.password || '');
    const [confirmPassword, setConfirmPassword] = useState(props?.driver?.password || '');

    // Personal Information
    const [firstName, setFirstName] = useState(props?.driver?.personalInfo?.firstName || '');
    const [lastName, setLastName] = useState(props?.driver?.personalInfo?.lastName || '');
    const [birthday, setBirthday] = useState(props?.driver?.personalInfo?.birthday ? dayjs(props?.personalInfo?.birthday) : null);
    const [gender, setGender] = useState(props?.driver?.personalInfo?.gender || '');
    const [phoneNumber, setPhoneNumber] = useState(props?.driver?.personalInfo?.phoneNumber || '');
    const [email, setEmail] = useState(props?.driver?.personalInfo?.email || '');
    const [address, setAddress] = useState(props?.driver?.personalInfo?.address || '');
    // Vehicle Information
    const [make, setMake] = useState(props?.driver?.vehicleInfo?.make || '');
    const [model, setModel] = useState(props?.driver?.vehicleInfo?.model || '');
    const [year, setYear] = useState(props?.driver?.vehicleInfo?.year || '');
    const [color, setColor] = useState(props?.driver?.vehicleInfo?.color || '');
    const [licensePlate, setLicensePlate] = useState(props?.driver?.vehicleInfo?.licensePlate || '');
    const [vin, setVin] = useState(props?.driver?.vehicleInfo?.vin || '');
    const [vehicleType, setVehicleType] = useState(props?.driver?.vehicleInfo?.vehicleType || '');
    // Driving Credentials
    const [driversLicenseNumber, setDriversLicenseNumber] = useState(props?.driver?.drivingCredentials?.driversLicenseNumber || '');
    const [licenseExpiryDate, setLicenseExpiryDate] = useState(props?.driver?.drivingCredentials?.licenseExpiryDate ? dayjs(props?.driver?.drivingCredentials?.licenseExpiryDate) : null);

    const {firebaseApp, setFirebaseApp} = useContext(FirebaseContext);

    const nav = useNavigate();
    const db = getFirestore(firebaseApp);
    const authFromApp = getAuth(firebaseApp);

    const displayError = (error) => {
        console.error(error);
        setErrorMessage(error);
        if (errorDivRef?.current) {
            errorDivRef.current?.focus();
        }
    };

    const submit = () => {
        if (password === null || password !== confirmPassword ) {
            displayError("Invalid password");
            return;
        }
        const driver = {
               personalInfo: {
                firstName: firstName,
                lastName: lastName,
                birthday: `${birthday.year()}/${birthday.month() + 1}/${birthday.date()}`,
                gender: gender,
                phoneNumber: phoneNumber,
                email: email,
                address: address,
               },
               vehicleInfo: {
                make: make,
                model: model,
                year: year,
                color: color,
                licensePlate: licensePlate,
                vin: vin,
                vehicleType: vehicleType,
               },
               drivingCredentials: {
                driversLicenseNumber: driversLicenseNumber,
                licenseExpiryDate: licenseExpiryDate ?`${licenseExpiryDate.year()}/${licenseExpiryDate.month() + 1}/${licenseExpiryDate.date()}` : null,
               }
           };
        if (editMode) {
            const driverRef = doc(db, DRIVERS_TABLE, props.driver.id);
            setDoc(driverRef, driver,{ merge: true }).then(() => {
                if (props.onAction) {
                    props.onAction();
                } else {
                    nav('/drivers');
                }
              }, (err) => {
                displayError(err.message);
              });
         } else {
            createUserWithEmailAndPassword(authFromApp, email, password)
                .then((credentials) => {
                    const uid = credentials.user.uid;
                    driver['uid'] = uid;
                    addDoc(collection(db, DRIVERS_TABLE), driver).then(() => {
                         if (props.onAction) {
                             props.onAction();
                         } else {
                             nav('/drivers');
                         }
                       }, (err) => {
                         displayError(err);
                       });
                }, (err) => {
                  displayError(err.message);
                });
         }
    };

    return (
        <div className="Driver">
            <div className="ErrorMessage" ref={errorDivRef} tabIndex={-1}>{errorMessage}</div>
            <Stack direction="column" spacing={2}>
                <div className="DriverTitle"><Button onClick={() => props.onBack() }><ArrowBackIcon /></Button> {editMode ? 'Edit': 'Add' } Driver</div>
                <h2>Login Information</h2>
                <TextField label="Email" value={email} onChange={(event) => setEmail(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} InputProps={{readOnly: editMode}} />
                { !editMode && (
                    <>
                        <TextField label="Password" type="password" value={password} onChange={(event) => setPassword(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                        <TextField label="Confirm Password" type="password" value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                    </>
                    )}
                <h2>Personal Information</h2>
                <TextField label="First Name" value={firstName} onChange={(event) => setFirstName(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <TextField label="Last Name" value={lastName} onChange={(event) => setLastName(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <DatePicker label="Date of Birth" onChange={(value) => setBirthday(value)} value={birthday} sx={{minWidth: 300, maxWidth: 500}}/>
                <Box sx={{ minWidth: 120, maxWidth: 300 }}>
                   <FormControl fullWidth>
                       <InputLabel>Gender</InputLabel>
                       <Select
                         value={gender}
                         onChange={(event) => { setGender(event.target.value); }}
                         label="Gender"
                       >
                            <MenuItem value={null}><em>None</em></MenuItem>
                            <MenuItem value={"male"}>Male</MenuItem>
                            <MenuItem value={"female"}>Female</MenuItem>
                            <MenuItem value={"other"}>Other</MenuItem>
                       </Select>
                   </FormControl>
                </Box>
                <MuiPhoneNumber disableAreaCodes={true} variant="outlined" label="Phone Number" defaultCountry={'us'} value={phoneNumber} onChange={(value) => { setPhoneNumber(value); }} sx={{minWidth: 300, maxWidth: 500}}/>

                <TextField label="Address" value={address} onChange={(event) => setAddress(event.target.value)} sx={{minWidth: 500, maxWidth: 800}} />

                <h2>Vehicle Information</h2>
                <TextField label="Make" value={make} onChange={(event) => setMake(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <TextField label="Model" value={model} onChange={(event) => setModel(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <TextField label="Year" value={year} onChange={(event) => setYear(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <TextField label="Color" value={color} onChange={(event) => setColor(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <TextField label="License Plate" value={licensePlate} onChange={(event) => setLicensePlate(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <TextField label="VIN" value={vin} onChange={(event) => setVin(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <Box sx={{ minWidth: 120, maxWidth: 300 }}>
                   <FormControl fullWidth>
                       <InputLabel>Vehicle Type</InputLabel>
                       <Select
                         value={vehicleType}
                         onChange={(event) => { setVehicleType(event.target.value); }}
                         label="Vehicle Type"
                       >
                            <MenuItem value={null}><em>None</em></MenuItem>
                            <MenuItem value={"car"}>Car</MenuItem>
                            <MenuItem value={"suv"}>S.U.V</MenuItem>
                            <MenuItem value={"van"}>Van</MenuItem>
                            <MenuItem value={"bus"}>Bus</MenuItem>
                       </Select>
                   </FormControl>
                </Box>

                <h2>Driving Credentials</h2>
                <TextField label="Driver's License Number" value={driversLicenseNumber} onChange={(event) => setDriversLicenseNumber(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <DatePicker label="License Expiry Date" onChange={(value) => setLicenseExpiryDate(value)} value={licenseExpiryDate} sx={{minWidth: 300, maxWidth: 500}}/>

                <div className="DriverButton">
                    <Button variant="contained" onClick={submit}>{buttonTitle}</Button>
                </div>
            </Stack>
        </div>
    );
}

export default Driver;