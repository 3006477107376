import './Partner.css';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {Map, Marker, useMap, useMapsLibrary} from '@vis.gl/react-google-maps';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { getFirestore, addDoc, collection, doc, getDocs, runTransaction, setDoc } from "firebase/firestore";

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { FirebaseContext } from '../providers/FirebaseContext.js';

import { PARTNERS_TABLE } from '../libs/constants.js';

function Partner(props) {
    const editMode = props?.edit || false;
    const buttonTitle = editMode ? 'Update Partner' : 'Add Partner';

    // Partner Information
    const [name, setName] = useState(props?.partner?.name || null);
    const [serviceArea, setServiceArea] = useState(props?.partner?.serviceArea || null);
    // Region Information
    const [longitudeWest, setLongitudeWest] = useState(props?.partner?.longitudeWest || null);
    const [latitudeNorth, setLatitudeNorth] = useState(props?.partner?.latitudeNorth || null);
    const [longitudeEast, setLongitudeEast] = useState(props?.partner?.longitudeEast || null);
    const [latitudeSouth, setLatitudeSouth] = useState(props?.partner?.latitudeSouth || null);
    const [mapZoom, setMapZoom] = useState(8);

    const {firebaseApp, setFirebaseApp} = useContext(FirebaseContext);

    const nav = useNavigate();
    const db = getFirestore(firebaseApp);

    const submit = () => {
        const partner = {
            name: name,
            serviceArea: serviceArea,
            longitudeWest: longitudeWest,
            latitudeNorth: latitudeNorth,
            longitudeEast: longitudeEast,
            latitudeSouth: latitudeSouth,
           };
        if (editMode) {
            const partnerRef = doc(db, PARTNERS_TABLE, props.partner.id);
            setDoc(partnerRef, partner, { merge: true }).then(() => {
                if (props.onAction) {
                    props.onAction();
                } else {
                    nav('/partners');
                }
              }, (err) => {
                console.error(err);
              });
         } else {
            addDoc(collection(db, PARTNERS_TABLE), partner).then(() => {
                 if (props.onAction) {
                     props.onAction();
                 } else {
                     nav('/partners');
                 }
               }, (err) => {
                 console.error(err);
               });
         }
    };

    const coordinatesSet = () => {
        if (longitudeWest && latitudeNorth && longitudeWest && latitudeSouth &&
            longitudeWest !== '' && latitudeNorth !== '' && longitudeWest !== '' && latitudeSouth !== '') {
            return !isNaN(longitudeWest) && !isNaN(latitudeNorth) && !isNaN(longitudeWest) && !isNaN(latitudeSouth);
        }
        return false;
    };

    return (
        <div className="Partner">
            <Stack direction="column" spacing={2}>
                <div className="PartnerTitle"><Button onClick={() => props.onBack() }><ArrowBackIcon /></Button> {editMode ? 'Edit': 'Add' } Partner</div>
                <h2>Partner Information</h2>
                <TextField label="Name" value={name} onChange={(event) => setName(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <Box sx={{ minWidth: 120, maxWidth: 300 }}>
                   <FormControl fullWidth>
                       <InputLabel>Service Area</InputLabel>
                       <Select
                         value={serviceArea}
                         onChange={(event) => { setServiceArea(event.target.value); }}
                         label="Service Area"
                       >
                            <MenuItem value={null}><em>None</em></MenuItem>
                            <MenuItem value={"sac"}>Sacramento, CA</MenuItem>
                            <MenuItem value={"ja"}>Jamaica</MenuItem>
                       </Select>
                   </FormControl>
                </Box>
                <h2>Region Information</h2>
                <TextField label="Longitude (West)" value={longitudeWest} onChange={(event) => setLongitudeWest(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <TextField label="Latitude (North)" value={latitudeNorth} onChange={(event) => setLatitudeNorth(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <TextField label="Longitude (East)" value={longitudeEast} onChange={(event) => setLongitudeEast(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <TextField label="Latitude (South)" value={latitudeSouth} onChange={(event) => setLatitudeSouth(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                { coordinatesSet() &&
                    (
                    <>
                        <Box sx={{ maxWidth: 100 }}>
                           <FormControl fullWidth>
                               <InputLabel>Zoom Map</InputLabel>
                               <Select
                                 value={mapZoom}
                                 onChange={(event) => { setMapZoom(event.target.value); }}
                                 label="Map Zoom"
                               >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={11}>11</MenuItem>
                                    <MenuItem value={12}>12</MenuItem>
                               </Select>
                           </FormControl>
                        </Box>
                        <div className="MapSize">
                             <Map zoom={parseInt(mapZoom)} center={{lat: (parseFloat(latitudeNorth) + parseFloat(latitudeSouth))/ 2, lng: (parseFloat(longitudeEast) + parseFloat(longitudeWest))/ 2}}>
                               <Marker position={{lat: parseFloat(latitudeNorth), lng:  parseFloat(longitudeEast)}} title={'1'} />
                               <Marker position={{lat: parseFloat(latitudeNorth), lng:  parseFloat(longitudeWest)}}  title={'2'}/>
                               <Marker position={{lat: parseFloat(latitudeSouth), lng:  parseFloat(longitudeEast)}} title={'3'} />
                               <Marker position={{lat: parseFloat(latitudeSouth), lng:  parseFloat(longitudeWest)}}  title={'4'}/>
                             </Map>
                         </div>
                     </>
                    )

                }

                <div className="PartnerButton">
                    <Button variant="contained" onClick={submit}>{buttonTitle}</Button>
                </div>
            </Stack>
        </div>
    );
}

export default Partner;
