import { ROUND_TRIP, EXCURSION, HOURLY_BASE, SAFE, SECURE, ULTRA_SAFE, MAX_PASSENGERS_ORIGINAL,
        COST_RENTAL_CAR_HOURLY_FB,
        COST_DRIVER_SAFE_HOURLY, COST_DRIVER_SECURE_HOURLY, MIN_FARE_DRIVER_SAFE,
        MULTIPLIER_REGION_JAM, MULTIPLIER_REGION_SAC, MULTIPLIER_REGION_ABQ, MAX_PASSENGERS_UPGRADE,
        MAX_PASSENGERS_CAR_INCLUDED, MAX_PASSENGERS_SUV_INCLUDED, MAX_PASSENGERS_VAN_INCLUDED, MAX_PASSENGERS_BUS_INCLUDED,
        MAX_PASSENGERS_CAR_SEATS, MAX_PASSENGERS_SUV_SEATS, MAX_PASSENGERS_VAN_SEATS, MAX_PASSENGERS_BUS_SEATS } from './constants.js';
import dayjs from 'dayjs';

import { getVehicleCount, getVehicleType, passengers_additional_fares } from './utils.js';

export function distanceToTime(distance) {
    const time = distance / 35;
    const wholePartNumber = Math.floor(time);
    const fractionPartNumber = time - wholePartNumber;
    if (fractionPartNumber < 0.25) {
        return wholePartNumber;
    } else if (fractionPartNumber < 0.5) {
        return wholePartNumber + 0.25;
    } else if (fractionPartNumber < 0.75) {
        return wholePartNumber + 0.50;
    } else if (fractionPartNumber >= 0.75) {
        return wholePartNumber + 0.75;
    } else if (wholePartNumber === 0) {
        return 0.25;
    } else {
        return wholePartNumber;
    }
}

const roundPrice = (price) => {
    return Math.round(price * 100) / 100;
};

export function allDayRate(props) {
    if (props.areaOfService === 'ja') {
        if (props.serviceLevel === SAFE) {
            return 800;
        } else if (props.serviceLevel === SECURE) {
            return 1600;
        }
    } else if (props.areaOfService === 'sac') {
        if (props.serviceLevel === SAFE) {
            return 1200;
        } else if (props.serviceLevel === SECURE) {
            return 1800;
        }
    } else if (props.areaOfService === 'abq') {
        if (props.serviceLevel === SAFE) {
            return 1200;
        } else if (props.serviceLevel === SECURE) {
            return 1800;
        }
    }
}

export function hourlyRate(props) {
    console.log(props);
    const codeType = props?.codeInfo?.type || '';
    // console.log(codeType);
    if (props.areaOfService === 'ja') {
        if (props.serviceLevel === SAFE || codeType === 'upgrade') {
            return COST_DRIVER_SAFE_HOURLY * MULTIPLIER_REGION_JAM;
        } else if (props.serviceLevel === SECURE) {
            return COST_DRIVER_SECURE_HOURLY * MULTIPLIER_REGION_JAM;
        }
    } else if (props.areaOfService === 'sac') {
        if (props.serviceLevel === SAFE || codeType === 'upgrade') {
            return COST_DRIVER_SAFE_HOURLY * MULTIPLIER_REGION_SAC;
        } else if (props.serviceLevel === SECURE) {
            return COST_DRIVER_SECURE_HOURLY * MULTIPLIER_REGION_SAC;
        }
    } else if (props.areaOfService === 'abq') {
        if (props.serviceLevel === SAFE || codeType === 'upgrade') {
            return COST_DRIVER_SAFE_HOURLY * MULTIPLIER_REGION_ABQ;
        } else if (props.serviceLevel === SECURE) {
            return COST_DRIVER_SECURE_HOURLY * MULTIPLIER_REGION_ABQ;
        }
    }
}

const discountPeriods = [
    {
        startDate: '2024/06/29',
        endDate: '2024/07/08',
        discount: 0.80,
        minCost: 40,
    }
];

// TODO:  Use for testing, implement .env to address temp elevation to export for testing
function discount(totalCost, props) {
    const today = dayjs();
    for (const discountPeriod of discountPeriods ) {
        const startDate = dayjs(discountPeriod.startDate);
        const endDate = dayjs(discountPeriod.endDate);
        if (today.isAfter(startDate) && today.isBefore(endDate)) {
            const discountedCost =  totalCost * discountPeriod.discount;
            return (discountPeriod?.minCost === undefined || discountedCost > discountPeriod.minCost) ? discountedCost: discountPeriod.minCost;
        }
    }
    return totalCost;
}

function code(totalCost, codeInfo) {
    if (!codeInfo || !codeInfo?.type) {
        return totalCost;
    }
    const type = codeInfo.type;
    if (type === 'discount') {
        const discountType = codeInfo?.discountType || '';
        const discountAmount = codeInfo?.discountAmount ? parseFloat(codeInfo?.discountAmount): 0;
        const used = codeInfo?.used ? parseInt(codeInfo?.used): 0;
        const quantity = codeInfo?.quantity ? parseInt(codeInfo?.quantity): 0;
        if (quantity === 0 || used < quantity) {
            if (discountType === 'percentage') {
                return totalCost * (discountAmount / 100);
            } else if (discountType === 'amount') {
                return totalCost - discountAmount;
            }
        }
    }
    return totalCost;
}

function region(totalRegionCost, props) {
    let regionCost = totalRegionCost; // totalCost + (totalCost * 0); // 2.00);
    const { areaOfService, tripType,  allDay: isAllDay, vehicleRate: rate, vehicleCount: count, estimatedDuration } = props;
    return regionCost;
}

function passenger (totalBaseCost, props) {
    // let passengerCost = totalCost;
    const { vehicleType, vehicleCount, passengers: passengerCount } = props;
    let passengerCost = 0;
    let maxIncludedPassengers;
    let maxSeats;

    switch (vehicleType) {
        case 'car':
            maxIncludedPassengers = MAX_PASSENGERS_CAR_INCLUDED;
            maxSeats = MAX_PASSENGERS_CAR_SEATS;
            break;
        case 'suv':
            maxIncludedPassengers = MAX_PASSENGERS_SUV_INCLUDED;
            maxSeats = MAX_PASSENGERS_SUV_SEATS;
            break;
        case 'van':
            maxIncludedPassengers = MAX_PASSENGERS_VAN_INCLUDED;
            maxSeats = MAX_PASSENGERS_VAN_SEATS;
            break;
        case 'bus':
            // For buses, the cost is simply the totalCost multiplied by the number of passengers
            return totalBaseCost * passengerCount; // Each passenger pays the base fare
        default:
            throw new Error('Invalid vehicle type');
    }

    const totalMaxIncludedPassengers = maxIncludedPassengers * vehicleCount;

    if (passengerCount > totalMaxIncludedPassengers) {
        const additionalPassengers = passengerCount - totalMaxIncludedPassengers;
        passengerCost = totalBaseCost * additionalPassengers;
    }

    return passengerCost;
}

function vehicle(totalVehicleCost, props) {
    let vehicleCost = 0;
    const { allDay: isAllDay, vehicleRate: rate, vehicleCount: count, estimatedDuration } = props;

    if (isAllDay) {
        // V = r * c
        vehicleCost += rate * count;
    } else {
        if (!estimatedDuration) {
            return vehicleCost;
        }
        const time = Math.ceil(estimatedDuration.value / (60 * 60)); // Convert seconds to hours
        // V = r * t * c
        vehicleCost += rate * time * count;
    }
    /* 20240923, review, breaks tests, soln:  add property to props and pass to priceCalc, 2x in the priceCalc the vehicle cost for a round trip, origin/main - 0bf2cfdca0b390873a341ad887d0c0994f673e44
    Note:  the cost for the second/return ride leg must also be calculated in the calculatePrice to make money for the return trip and pay the driver
     else {
        let time = estimatedDuration.value / (60 * 60);
        if (returnEstimatedDuration?.value) {
            // Add the return trip time for round-trip
            time += returnEstimatedDuration.value / (60 * 60);
            // Double vehicle count for round-trip
            count = count * 2;
        }
        // V = r*t*c
        vehicleCost+= rate * time * count;
    }
    */
    return vehicleCost + totalVehicleCost;
}

function timeRate (totalTimeCost, props) {
    let timeCost = totalTimeCost;
    return timeCost;
}

function baseFare(totalBaseCost, props) {
    let baseCost = totalBaseCost;
    return baseCost;
}

function tax(totalTaxCost, props) {
    return totalTaxCost;
}

function bookingCharge(totalCost, props) {
    // multi-leg/multi-car/multi-passenger
    return totalCost;
}

// Export the discount function only in test environments
    if (process.env.NODE_ENV === 'test') {
        module.exports.discount = discount;
        module.exports.passenger = passenger;
        module.exports.vehicle = vehicle;
        module.exports.region = region;
    }

export function calculatePrice(props) {
    let totalCost = 0;
    let totalBaseCost = 0;
    let vehicleCost = 0;
    let passengerCost = 0;
    let regionCost = 0;


    for (const rideDataItem of props.rideData) {
        totalBaseCost = 0;
        if (rideDataItem.allDay) {
            totalBaseCost = allDayRate(props);

        } else {
            if (!rideDataItem.estimatedDuration) {
                continue;
            }
            const hours = rideDataItem.estimatedDuration.value / (60 * 60);
            totalBaseCost =  hourlyRate({areaOfService: props.areaOfService, serviceLevel: props.serviceLevel}) * hours;
        }
        totalCost += totalBaseCost;

        passengerCost = passenger(totalBaseCost, rideDataItem);
        vehicleCost = vehicle(vehicleCost, rideDataItem);
        // region();
    }

    totalCost += passengerCost + vehicleCost;
    // 20240924:eahj, consider applying one or the other, then tell them the one that was applied
    totalCost = discount(totalCost, props);
    totalCost = code(totalCost, props?.codeInfo);

    if (props.tripType === ROUND_TRIP) {
        return roundPrice(2 * totalCost);
    } else {
        return roundPrice(totalCost);
    }
}
