import './Code.css';
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { getFirestore, addDoc, collection, doc, getDocs, runTransaction, setDoc } from "firebase/firestore";

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { FirebaseContext } from '../providers/FirebaseContext.js';

import { CODES_TABLE, ENTITIES_TABLE } from '../libs/constants.js';

function Code(props) {
    const editMode = props?.edit || false;
    const buttonTitle = editMode ? 'Update Code' : 'Add Code';

    // Code Information
    const [code, setCode] = useState(props?.code?.code || null);
    const [type, setType] = useState(props?.code?.type || null);
    const [entityId, setEntityId] = useState(props?.code?.entityId || null);
    const [region, setRegion] = useState(props?.code?.region || null);
    const [quantity, setQuantity] = useState(props?.code?.quantity || '0');
    const [used, setUsed] = useState(props?.code?.used || '0');
    const [discountType, setDiscountType] = useState(props?.code?.discountType || null);
    const [discountAmount, setDiscountAmount] = useState(props?.code?.discountAmount || null);
    const [expiration, setExpiration] = useState(props?.code?.expiration ? dayjs(props?.code?.expiration) : null);
    const [singleUse, setSingleUse] = useState(props?.code?.singleUse || false);
    const [entities, setEntities] = useState([]);
    const {firebaseApp, setFirebaseApp} = useContext(FirebaseContext);

    const nav = useNavigate();
    const db = getFirestore(firebaseApp);

    const fetchEntities = async () => {
        getDocs(collection(db, ENTITIES_TABLE))
              .then((querySnapshot)=>{
                  const newData = querySnapshot.docs
                      .map((doc) => ({...doc.data(), id:doc.id }));
                  setEntities(newData);
              })
    };

    useEffect(
        () => {
            fetchEntities();
        }
    );

    const submit = () => {
        const codeObj = {
            code: code,
            type: type,
            quantity: quantity,
            used: used,
            discountType: discountType,
            discountAmount: discountAmount,
            entityId: entityId,
            region: region,
            singleUse: singleUse,
            expiration: expiration ? `${expiration.year()}/${expiration.month() + 1}/${expiration.date()}` : null,
           };
        if (editMode) {
            const codeRef = doc(db, CODES_TABLE, props.code.id);
            setDoc(codeRef, codeObj,{ merge: true }).then(() => {
                if (props.onAction) {
                    props.onAction();
                } else {
                    nav('/codes');
                }
              }, (err) => {
                console.error(err);
              });
         } else {
            addDoc(collection(db, CODES_TABLE), codeObj).then(() => {
                 if (props.onAction) {
                     props.onAction();
                 } else {
                     nav('/codes');
                 }
               }, (err) => {
                 console.error(err);
               });
         }
    };

    return (
        <div className="Code">
            <Stack direction="column" spacing={2}>
                <div className="CodeTitle"><Button onClick={() => props.onBack() }><ArrowBackIcon /></Button> {editMode ? 'Edit': 'Add' } Code</div>
                <h2>Code Information</h2>
                <TextField label="Code" value={code} onChange={(event) => setCode(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <Box sx={{ minWidth: 120, maxWidth: 300 }}>
                   <FormControl fullWidth>
                       <InputLabel>Entity</InputLabel>
                       <Select
                         value={entityId}
                         onChange={(event) => { setEntityId(event.target.value); }}
                         label="Entity"
                       >
                            <MenuItem value={null}><em>None</em></MenuItem>
                            {entities.map((entity, i) => {
                                return (<MenuItem value={entity.id}>{entity.type}: {entity.name}</MenuItem>);
                            })}
                       </Select>
                   </FormControl>
                </Box>

                <Box sx={{ minWidth: 120, maxWidth: 300 }}>
                   <FormControl fullWidth>
                       <InputLabel>Region</InputLabel>
                       <Select
                         value={region}
                         onChange={(event) => { setRegion(event.target.value); }}
                         label="Region"
                       >
                            <MenuItem value={null}><em>None</em></MenuItem>
                            <MenuItem value={"sac"}>Sacramento, CA</MenuItem>
                            <MenuItem value={"ja"}>Jamaica</MenuItem>
                            <MenuItem value={"abq"}>Albuquerque, NM</MenuItem>
                       </Select>
                   </FormControl>
                </Box>
                <Box sx={{ minWidth: 120, maxWidth: 300 }}>
                   <FormControl fullWidth>
                       <InputLabel>Type</InputLabel>
                       <Select
                         value={type}
                         onChange={(event) => { setType(event.target.value); }}
                         label="Type"
                       >
                            <MenuItem value={null}><em>None</em></MenuItem>
                            <MenuItem value={"discount"}>Discount</MenuItem>
                            <MenuItem value={"upgrade"}>Upgrade</MenuItem>
                       </Select>
                   </FormControl>
                </Box>
                { type === 'discount' && (
                    <>
                        <Box sx={{ minWidth: 120, maxWidth: 300 }}>
                        <FormControl fullWidth>
                            <InputLabel>Discount Type</InputLabel>
                            <Select
                                value={discountType}
                                onChange={(event) => { setDiscountType(event.target.value); }}
                                label="Discount Type"
                            >
                                <MenuItem value={"none"}>None</MenuItem>
                                <MenuItem value={"percentage"}>Percentage</MenuItem>
                                <MenuItem value={"amount"}>Amount</MenuItem>
                            </Select>
                        </FormControl>
                        </Box>
                        <TextField label="Discount Amount" value={discountAmount} onChange={(event) => setDiscountAmount(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                    </>
                ) }
                <TextField label="Quantity" value={quantity} onChange={(event) => setQuantity(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <TextField label="Used" value={used} onChange={(event) => setUsed(event.target.value)} sx={{minWidth: 300, maxWidth: 500}} />
                <div className="SingleUse">
                    <Checkbox checked={singleUse}  onChange={(event) => { setSingleUse(event.target.checked); }} /> Single Use
                </div>
                <DatePicker label="Expiration" onChange={(value) => setExpiration(value)} value={expiration} sx={{minWidth: 300, maxWidth: 500}}/>
                <div className="CodeButton">
                    <Button variant="contained" onClick={submit}>{buttonTitle}</Button>
                </div>
            </Stack>
        </div>
    );
}

export default Code;
