import './BookRideEdit.css';
import '../pages/BookRide.css';

import React, { useState, useContext, useEffect, ChangeEvent, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import {Map, Marker, useMapsLibrary} from '@vis.gl/react-google-maps';

import MuiPhoneNumber from 'mui-phone-number';

import { getFirestore, addDoc, collection, doc, getDocs, runTransaction, setDoc } from "firebase/firestore";

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

import AutoCompleteTextField from '../components/AutoCompleteTextField';
import SignIn from '../pages/SignIn';
import Signup from '../pages/Signup';
import RideLeg from './RideLeg';
import StripePayment from '../pages/StripePayment';
import UpdatePayment from '../pages/UpdatePayment';
import RideMap from '../components/RideMap';

import { calculatePrice } from '../libs/prices.js';
import { getPaymentHistory, getDriverId, getUserInfo, getServiceLevel, setUpPhoneNumber, getPaymentInfo } from '../libs/utils.js';
import { BOOKED_RIDES_TABLE, CONFIRMATION_TABLE, DRIVERS_TABLE, SAFE, SECURE, ULTRA_SECURE, VIEWPORT_LIMIT, PHONE, IS_PROD, ONE_WAY, ROUND_TRIP} from '../libs/constants.js';

function BookRideEdit(props) {
    const ride = props.ride;
    const [code, setCode] = useState(ride.code || null);
    const [codeInfo, setCodeInfo] = useState(ride.codeInfo || null);
    const {auth, setAuth} = useContext(AuthContext);

    const originalPrice = ride.price.total;
    const [phone, setPhone] = useState(ride.primary.phoneNumber);
    const [price, setPrice] = useState(ride.price.total);
    const [fee, setFee] = useState(ride.price.fee);
    const [tip, setTip] = useState(ride.price.tip);
    const [confirmationCode, setConfirmationCode] = useState(ride.confirmationCode);
    const [serviceLevel, setServiceLevel] = useState(ride.level);
    const [consentToTexting, setConsentToTexting] = useState(ride.primary.consentToTexting);
    const [phoneNumber, setPhoneNumber] = useState(ride.primary.phone);
    const [firstName, setFirstName] = useState(ride.primary.firstName);
    const [lastName, setLastName] = useState(ride.primary.lastName);
    const [email, setEmail] = useState(ride.primary.email);

    const [legs, setLegs] = useState(ride.legs);

    const getAreaOfService = (rideLegs) => {
        for (const leg of rideLegs) {
            if (leg.from.includes('Sacramento') || leg.to.includes('Sacramento')) {
                return 'sac';
            } else if (leg.from.includes('Albuquerque') || leg.to.includes('Albuquerque')) {
                return 'abq';
            } else if (leg.from.includes('Jamaica') || leg.to.includes('Jamaica')) {
               return 'ja';
            }
        }
    };
    const [areaOfService, setAreaOfService] = useState(getAreaOfService(ride.legs));
    const tripTypeFromRide = (rideData) => {
        if (rideData?.legs) {
            for (const leg of rideData.legs) {
                if (leg?.returnEstimatedDuration?.value) {
                    return ROUND_TRIP;
                }
            }
        }
        return ONE_WAY;
    };
    const [tripType, setTripType] = useState(ride?.tripType || tripTypeFromRide(ride));

    const {firebaseApp} = useContext(FirebaseContext);
    const db = getFirestore(firebaseApp);
  
    const nav = useNavigate();

    const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

    const onChangeServiceLevel = (event: ChangeEvent) => {
         setServiceLevel(event.target.value);
         const newFee = calculatePrice({rideData: legs, serviceLevel: event.target.value, areaOfService: areaOfService});
         setFee(newFee);
         setPrice(newFee + tip);
    };

    const submit = (paymentInfo) => {
        if (originalPrice === price) {
            const rydeRef = doc(db, BOOKED_RIDES_TABLE, ride.id);
            setDoc(rydeRef,
                {
                  legs: legs,
                  primary: {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phoneNumber,
                    consentToTexting: consentToTexting
                  },
                  level: serviceLevel,
                  code: code,
                  codeInfo: codeInfo,
                  tripType: tripType,
              },
                { merge: true }
              ).then(() => {
                if (props.onEdit) {
                    props.onEdit();
                } else {
                    nav('/');
                }
              }, (err) => {
                console.error(err);
              });
        } else if (paymentInfo) {
            const rydeRef = doc(db, BOOKED_RIDES_TABLE, ride.id);
            setDoc(rydeRef,
                {
                  legs: legs,
                  primary: {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phoneNumber,
                    consentToTexting: consentToTexting
                  },
                  level: serviceLevel,
                  price: {total: price, fee: fee, tip: tip},
                  paymentConfirm: paymentInfo,
                  code: code,
                  codeInfo: codeInfo,
                  tripType: tripType,
                },
                { merge: true }
              ).then(() => {
                if (props.onEdit) {
                    props.onEdit();
                } else {
                    nav('/');
                }
              }, (err) => {
                console.error(err);
              });
        }
    };

    const rideDataToLeg = (rideData) => {
         const pickUpDateTime = dayjs.tz(`${rideData.date.year()}/${rideData.date.month() + 1}/${rideData.date.date()} ${rideData.time.hour()}:${rideData.time.minute()}`, areaOfService === 'ja'? 'Jamaica': 'America/Los_Angeles').format();
         const returnPickUpDateTime = (rideData.returnDate && rideData.returnTime) ? dayjs.tz(`${rideData.returnDate.year()}/${rideData.returnDate.month() + 1}/${rideData.returnDate.date()} ${rideData.returnTime.hour()}:${rideData.returnTime.minute()}`, areaOfService === 'ja'? 'Jamaica': 'America/Los_Angeles').format() : null;
            return {
               from: rideData.from,
               to: rideData.to,
               fromPlaceId: rideData.fromPlaceId,
               toPlaceId: rideData.toPlaceId,
               pickUpDateTime: pickUpDateTime,
               returnFrom: rideData.returnFrom,
               returnTo: rideData.returnTo,
               returnFromPlaceId: rideData.returnFromPlaceId,
               returnToPlaceId: rideData.returnToPlaceId,
               returnPickUpDateTime: returnPickUpDateTime,
               passengers: rideData.passengers,
               vehicleType: rideData.vehicleType,
               bags: {
                   luggage: rideData.bags.luggage,
                   overSizedBags: rideData.bags.overSizedBags,
                   irregularBags: rideData.bags.irregularBags
                   },
               allDay: rideData.allDay,
               estimatedDuration: rideData.estimatedDuration,
               returnEstimatedDuration: rideData.returnEstimatedDuration,
               driverId: rideData.driverId,
               carId: rideData.carId,
               driverIds: rideData.driverIds,
               carIds: rideData.carIds,
               returnDriverId: rideData.returnDriverId,
               returnCarId: rideData.returnCarId,
               returnDriverIds: rideData.returnDriverIds,
               returnCarIds: rideData.returnCarIds,
            };
    };

    const shouldRecalute = (newLegs) => {
        if (legs.length !== newLegs.length) {
            return true;
        }
        for (let i = 0; i < legs.length; i++) {
            const currentLeg = legs[i];
            const newLeg = newLegs[i];
            if (currentLeg.from !== newLeg.from ||
                currentLeg.to !== newLeg.to ||
                currentLeg.allDay !== newLeg.allDay) {
                return true;
            }
        }
        return false;
    };

    const onChangeRideLeg = (index, updatedRideData) => {
        let updatedLegs = [];
        for (let i = 0; i < legs.length; i++) {
            if (i === index) {
                updatedLegs.push(rideDataToLeg(updatedRideData));
            } else {
                updatedLegs.push(legs[i]);
            }
        }
        const recalulate = shouldRecalute(updatedLegs);
        setLegs(updatedLegs);
        if (serviceLevel !== null && areaOfService !== null && recalulate) {
            const newFee = calculatePrice({rideData: updatedLegs, serviceLevel: serviceLevel, areaOfService: areaOfService, tripType: tripType, codeInfo: codeInfo});
            setFee(newFee);
            setPrice(newFee + tip);
        }
    };

    return (
        <div className="BookRideEdit">
            <div className="EditTitle"><Button onClick={() => props.onBack() }><ArrowBackIcon /></Button> Edit Ride</div>
            <Stack direction="column" spacing={4}>
               <h2>Confirmation Code {confirmationCode}</h2>
               <h2>Ride Details</h2>
               <Stack spacing={2} direction="column">
               {
                   legs.map( (leg, i) => {
                       return (
                           <Stack key={`rideLegsStack_${i}`}>
                               <Card variant="outlined" key={`rideLegsCard_${i}`}>
                                   <CardContent key={`rideLegsCardContent_${i}`}>
                                   <RideLeg key={`rideData_${i}`} onChange={(updatedRideData) => { onChangeRideLeg( i, updatedRideData); }} leg={leg} region={areaOfService} tripType={tripType}/>
                                   </CardContent>
                               </Card>
                           </Stack>
                       );
                   })
               }
              </Stack>
              <h2>Service Level</h2>
              <Stack direction="column" spacing={4}>
                  <Stack spacing={4} direction="column">
                      <Stack spacing={2} direction="column">
                          <Stack spacing={2} direction="row">
                              <RadioGroup row value={serviceLevel} onChange={onChangeServiceLevel}>
                                  <FormControlLabel value={SAFE} control={<Radio/>} label="Safe"/>
                                  <FormControlLabel value={SECURE} control={<Radio/>} label="Secure"/>
                              </RadioGroup>
                          </Stack>
                          {serviceLevel === SAFE && (
                          <div>
                              Our Safe service is a high-quality ride with a vetted professional driver and a nice, clean, well-maintained vehicle.
                          </div>)}
                          {serviceLevel === SECURE && (
                          <div>
                              Our Secure service is a high-quality ride with an armed professional driver and a nice, clean, well-maintained vehicle.
                          </div>)}
                      </Stack>
                      <div>
                          <h3>Service Fee: {priceFormatter.format(fee)} </h3>
                      </div>
                  </Stack>
              </Stack>
              <h2>Primary Passenger</h2>
              <Stack spacing={4} direction="column">
                  <Stack spacing={5} direction={"row"}>
                      <TextField label="First Name" variant="outlined" type="text" value={firstName} onChange={(event) => { setFirstName(event.target.value); }} InputLabelProps={{ shrink: (firstName !== '')}} sx={{minWidth: 300, maxWidth: 500}}/>
                      <TextField label="Last Name" variant="outlined" type="text" value={lastName} onChange={(event) => { setLastName(event.target.value); }} InputLabelProps={{ shrink: (lastName !== '')}} sx={{minWidth: 300, maxWidth: 500}}/>
                  </Stack>
                  <Stack spacing={2}>
                      <TextField label="Email" variant="outlined" type="email" value={email} onChange={(event) => { setEmail(event.target.value); }} InputLabelProps={{ shrink: (email !== '')}} sx={{minWidth: 300, maxWidth: 500}}/>
                      <div>Needed for confirmations and reservation changes</div>
                  </Stack>
                  <Stack spacing={2} direction={"row"}>
                      <MuiPhoneNumber disableAreaCodes={true} variant="outlined" label="Phone Number" defaultCountry={'us'} value={phoneNumber} onChange={(value) => { setPhoneNumber(value); }} />
                      <div>
                          <Checkbox checked={consentToTexting}  onChange={(event) => { setConsentToTexting(event.target.checked);}} /> I consent to receive text messages about my ryde. Message and data rates may apply.
                      </div>
                  </Stack>
              </Stack>
              <h2>Payment</h2>
             { codeInfo && code && (<div>Promo Code: {code}</div>)}
             { originalPrice !== price && (
              <Stack direction={"row"}>
                  <div className="StripePayment">
                      <UpdatePayment price={price - originalPrice} submit={submit} email={email} live={IS_PROD} paymentHistory={getPaymentHistory(ride.paymentConfirm)}/>
                  </div>
                  <Stack direction="column">
                      <div>
                          Total Paid: {!isNaN(originalPrice) ? priceFormatter.format(originalPrice): '0'}
                       </div>
                       <div>
                          New Amount: {!isNaN(price) ? priceFormatter.format(price): '0'}
                      </div>
                      <div>
                          Difference: {!isNaN(price - originalPrice) ? priceFormatter.format(price - originalPrice): '0'}
                      </div>
                  </Stack>
              </Stack>)}
              { originalPrice === price && (
                <Stack>
                    <div className="Total">
                      Total Paid: {!isNaN(originalPrice) ? priceFormatter.format(originalPrice): '0'}
                    </div>
                    <div>
                        <Button variant="contained" onClick={() => submit()}>Update</Button>
                    </div>
                </Stack>
               )}
            </Stack>
            <h2>Payment History</h2>
            <Stack direction="column" spacing={2}>
                <div>
                    <span className="PaymentId">Strip Id</span>
                    <span className="PaymentType">Type</span>
                    <span className="PaymentAmount">Amount</span>
                </div>
                {
                    getPaymentHistory(ride.paymentConfirm).map((payment, i) => {
                        const paymentInfo = getPaymentInfo(payment);
                            return (<div>
                                <span className="PaymentId">{paymentInfo.id}</span>
                                <span className={paymentInfo.type === 'refund' ? "PaymentTypeRefund" :"PaymentTypeIntent" }>{paymentInfo.type}</span>
                                <span className="PaymentAmount">{priceFormatter.format(paymentInfo.amount / 100)}</span>
                                </div>);
                        }
                    )
                 }
            </Stack>
        </div>
    );
}

export default BookRideEdit;